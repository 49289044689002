.users {
	display: flex;
	flex-direction: column;

	&.is-empty {
		.meta {
			display: none;
		}
	}

	&.account-limit-reached {
		.icon-users-warning {
			display: inline-block;
		}
	}

	.meta {
		display: flex;
		align-items: center;

		.meta-info {
			flex: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-weight: 600;
		}

		.icon-users-warning {
			color: var(--warning-icon-color);
			margin-right: 7px;
			vertical-align: sub;
			display: none;
		}
	}
}

.users-body {
	.empty-view {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items:center;
		align-content: center;

		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;

		h3 {
			color: var(--empty-item-color);
		}
	}

	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	position: relative;
}

.users {
	.sj-message {
		width: 100%;
		max-width: 100%;
		margin-bottom: 20px;

		@include mobile {
			max-height: 100px;
			overflow-y: auto;

			padding: 10px;

			> p {
				margin: 0;
			}
		}
	}
}

@import "./usersList";
