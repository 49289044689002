.feedback-dialog .sj-overlay-content {
	.feedback-content {
		position: relative;
	}

	.feedback-success {
		display: none;
		position: absolute;
		top: 0;

		// center vertically slightly
		width: 100%;
		top: 20%;
		text-align: center;

		p {
			font-weight: bold;
			margin: 10px;
		}

		.feedback-close {
			margin-right: 0;
		}
	}

	.icon-feedback-success {
		color: #fff;
		border-radius: 50%;
		background-color: #ccc;
		padding: 13px;
		width: 1em;
		height: 1em;
		margin: 40px auto 50px;
	}

	.rating {
		display: flex;
		align-items: center;

		h5 {
			margin-right: 10px;
		}
	}

	.sj-message {
		width: auto;
		max-width: 100%;
		margin: 0 auto 15px;
		text-align: left;
	}

	.negative {
		display: none;
	}

	.has-error {
		.negative {
			display: block;
		}

		.info {
			display: none;
		}
	}

	.was-successful {
		.feedback-form {
			visibility: hidden;
		}
		.feedback-success {
			display: block;
		}
	}

	.feedback-text {
		margin-top: 15px;
		margin-bottom: 10px;
		box-sizing: border-box;

		width: 100%;
		height: 200px;
		padding: 9px;
		color: var(--feedback-text-color);

		resize: none;

		border: 1px solid var(--feedback-textarea-border-color);

		@include placeholder() {
			color: var(--feedback-text-placeholder-color);
		}
	}
}
