.email-dirty-overlay {
	.overlay-title {
		white-space: normal;
	}

	p {
		margin: 0;
	}

	.email-wrapper {
		display: flex;
		margin: 20px 0;
	}

	.dirty-email {
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.edit-button {
		@extend %icon-link;

		margin-left: 8px;
	}
}
