snapshot-list {
	> .automatic-snapshot-hint {
		margin-top: 27px;
	}

	> div {
		margin-bottom: 13px;
	}
}

.snapshots-list-loader {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 200px;

	color: var(--loading-icon-color);
}

.snapshots-empty,
.snapshots-error {
	.sj-message {
		margin-top: 20px;
		width: 100%;
		max-width: none;
	}
}

.snapshots-grid {
	list-content list-item .is-restorable {
		cursor: pointer;
		@include hover {
			background-color: var(--snapshot-list-item-hover-bg);
			.item-name {
				color: var(--fm-item-list-hover-color)
			}
		}
	}

	.item-label {
		display: block;
	}

	.item-name {
		flex: 1;
	}

	.item-date {
		width: 140px;
	}

	.item-type {
		width: 120px;
		@include mobile() {
			display: none;
		}
	}

	.item-button-container {
		@extend %item-list-button-container;
	}
}

.snapshot-meta-info-value {
	font-weight: bold;
}
