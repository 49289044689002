@import "./shareManagerHeader";

.sharemanager {
	display: flex;
	flex-direction: column;
}

.sharemanager-body {
	@include error-or-empty-view {
	}

	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	position: relative;
}

@import "./shareItemList";
