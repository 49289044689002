.app-status {

	height: 100%;

	display: flex;
	flex-direction: column;

	.content-wrap {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;

		/* limit for screens more than 479px */
		@media screen and (min-width: 480px) {
			width: 100%;
			max-width: 470px;
		}

		margin-top: calc($header-height + $header-bottom-space);
		text-align: center;

		margin-bottom: $footer-height;

		.status-title {
			margin: 0;
			color: var(--status-item-color);
		}

		.status-message {
			margin: 16px 0 0;
		}

		.status-link {
			margin-top: 35px;

			.icon-back-to-login {
				color: var(--status-item-color);
				margin-right: 10px;
			}
		}
	}
}
