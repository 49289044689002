.share-viewmode-editor {
	display: flex;

	label {
		margin-right: 10px;
	}

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;

		box-sizing: border-box;
		width: 44px;
		height: 44px;

		cursor: pointer;

		color: var(--icon-radio-color);
		border: 1px solid var(--icon-radio-color);

		border-radius: $editorBorderRadius;
	}

	input[type='radio'] {
		display: none;

		&:checked + .icon {
			cursor: auto;

			border-color: var(--icon-radio-selected-color);
			color: var(--icon-radio-selected-color);
		}
	}
}
