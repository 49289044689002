#sfm-footer {
	$footer-element-height: $footer-height - $base-font-size - 12px;
	$footer-vert-padding: ($footer-height - $footer-element-height) * 0.5;

	flex: none;
	display: flex;
	align-items: flex-start;


	width: 100%;
	padding: $footer-vert-padding $header-footer-hor-padding;
	background: var(--sfm-footer-background-color);
	position: relative;
	box-sizing: border-box;
	line-height: $footer-element-height;

	.sj-lang-flyout,
	.sj-flyout.applink {
		z-index: 5;
		// Only required for Firefox
		vertical-align: middle;

		.trigger .label {
			font-size: $base-font-size;
		}

		.content {
			top: auto;
			bottom: 44px;
		}
	}

	.sj-lang-flyout {
		.content {
			right:auto;
			left: -10px;
		}
	}

	.sj-flyout.applink {
		.content {
			right: auto;
			left: -10px;

			> li > a {
				display: block;
			}
		}
	}

	.links {
		flex: 1;

		> li {
			display: inline-block;
			white-space: nowrap;
			&:before {
				content: '\2502';
				color: var(--sfm-footer-separator-border-left-color);
				margin: 0 6px 0 3px;
			}

			&:first-child {
				&:before {
					content: '';
					margin-right: 0;
					margin-left: 0;
				}
			}

			> a {
				padding-bottom: 2px;
				outline: 0;
				text-decoration: none;
				font-weight: normal;
				color: var(--sfm-footer-link-color);

				@include active {
					color: var(--sfm-footer-link-active-color);
					border-bottom: var(--sfm-footer-link-active-underline-color) solid 3px;
				}

				@include hover {
					color: var(--sfm-footer-link-hover-color);
					border-bottom: var(--sfm-footer-link-hover-underline-color) solid 3px;
				}
			}

			&.darkmode-toggle {
				//overwrite line-height to keep toggle bullet on it's place
				line-height: 26px;
			}
		}
	}

	.extra {
		@include mobile {
			width: 150px;
			text-align: right;
		}

		margin-left: 30px;

		$footer-btn-height: 24px;

		.footer-btn {
			display: inline-block;
			height: $footer-btn-height - 2px;
			line-height: $footer-btn-height - 2px;
			font-weight: 600;
			text-align: center;
			padding: 0 8px;
			cursor: pointer;
			border-radius: $buttonBorderRadius;
			border-width: 1px;
			border-style: solid;
		}


		.feedback {
			color: var(--sfm-footer-extra-feedback-color);
			background: var(--sfm-footer-extra-feedback-background-color);
			border-color: var(--sfm-footer-extra-feedback-background-color);

			@include hover {
				background: var(--sfm-footer-extra-feedback-hover-background-color);
				border-color: var(--sfm-footer-extra-feedback-hover-background-color);
			}

			@include active {
				background: var(--sfm-footer-extra-feedback-active-background-color);
				border-color: var(--sfm-footer-extra-feedback-active-background-color);
			}
		}

		.powered_by {
			color: var(--sfm-footer-link-color);

			@include hover {
				color: var(--sfm-footer-link-hover-color);
			}
		}

	}

	.show_eu_cookie & {
		margin-bottom: $eu_cookie_height;

		@include tablet() {
			margin-bottom: $eu_cookie_height_tablet;
		}
		@include mobile() {
			margin-bottom: $eu_cookie_height_mobile;
		}
		@include mobile_small() {
			margin-bottom: $eu_cookie_height_mobile_small;
		}
	}
}
