.app-files {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	.filemanager {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
	}
}

.app-files-header {
	position: fixed;
	left: calc($content-left-offset - $fm-dropzone-size);
	right: calc($content-right-offset - $fm-dropzone-size);
	top: $header-height;
	width: auto;

	height: $menubar-height;

	@include portable {
		height: $menubar-height + $button-height;
	}

	// use padding to cover the items with bgcolor that may scroll behind the header
	padding: $header-bottom-space $fm-dropzone-size 0;
	z-index: 4;

	background-color: var(--sfm-filemanager-background);

	@include desktop {
		left: calc($content-left-offset-desktop - $fm-dropzone-size);
	}

	@include mobile {
		left: 0;
		right: 0;
		padding-left: $content-left-offset-mobile-small;
		padding-right: $content-right-offset-mobile-small;
	}

	.menubar-row {
		height: $button-height;
		display: flex;
		align-items: center;

		.search-form {
			flex: 1 1 auto;

			max-width: 1030px;
			min-width: 650px;

			@include portable {
				max-width: none;
				min-width: 0;
			}
		}

		.snapshot-metainfo {
			flex: 1 1 auto;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		overflow-menubar {
			flex: 1 1 0;

			min-width: $button-height;
			@include desktop {
				min-width: 100px;
			}

			margin-left: var(--gap-s);
		}

		.select-cancel {
			margin-right: 0;
			margin-left: var(--gap-s);
			min-width: $button-height;

			&.sj-button-disabled {
				display: none;
			}
		}
	}

	.toolbar-row {
		display: flex;
		align-items: center;

		margin-top: var(--gap-l);
		@include portable {
			/* compensate 2 liner menubar */
			margin-top: calc($button-height + var(--gap-l));
		}

		.file-type-count,
		.file-selected-count {
			margin-left: var(--gap-xs);
			&:last-of-type {
				margin-right: var(--gap-l);
			}
			font-weight: 400;
			font-size: 18px;
			white-space: nowrap;
		}

		.toolbar {
			margin-left: var(--gap-s);
			flex: 1;
			justify-content: end;
		}
	}

	@include tablet {
		.file-selected-count {
			display: none;
		}
	}

	@include mobile {
		.file-type-count,
		.file-selected-count {
			display: none;
		}
	}

	.sj-menuitem-disabled {
		display: none;
	}
}
