.sj-overlay.upload-conflict-dialog {
	.overlay-buttons {
		display: flex;
		flex-direction: column;

		.sj-button {
			min-width: 270px; /* Looks odd otherwise. The default is 100px for buttons, too small here. */
			margin: 10px auto;
		}

		.sj-button:not(:first-of-type):not(:last-of-type) {
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}


	.remember-decision {
		margin: 30px auto 0;
	}
}
