.fm-target-dialog {
	.sj-overlay-content {
		width: 600px;
	}
}

.target-dialog {
	@include mobile_small {
		display: flex;
		flex-direction: column;
		height: 100%;

		.target-dialog-content {
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;
		}
	}

	.overlay-buttons {
		flex: 0;
	}
}

.target-dialog-content {
	.filemanager-header {
		display: flex;
	}

	.button-new-folder {
		margin-left: auto;
		margin-top: -1px;

		.sj-button {
			@include link-styled;
		}
	}

	.folder-breadcrumb {
		height: 29px;

		font-size: $base-font-size;
		font-weight: 700;

		.root ~ .inner .current {
			margin-left: 0;
		}

		.icon-level-up {
			font-size: 22px;
			margin-top: 1px;
			padding-left: 6px;
			padding-right: 6px;
		}

		.root, .segment:not(.current), .arrow, .hellip {
			display: none;
		}

		/* Not clickable: reset cursor */
		.icon.share {
			cursor: default;
		}
	}

	.filemanager {
		flex: 1 0 auto;
		margin-top: 0;

		height: (5 * $file-item-list-item-height) + 1px; /* exact 5 rows and one px for the top border */

		@include mobile() {
			overflow-y: auto;
		}
	}

	x-filemanager {
		padding-top: 0;
		height: 100%;
		margin: 0;
		overflow: auto;
		border: none;

		.selection-boundary {
			// Reset default positioning because it interfers with
			// the target dialog (resetting top, bottom, ... not necessary).
			position: static;
		}

		list-header {
			display: none;
		}

		list-content {
			margin: 0;
			padding: 0;
			border-top: var(--fm-item-list-border);

			list-item {
				.selecticon {
					display: none;
				}

				&.file .itemcontent {
					opacity: 0.3;
				}

				.itemcontent {
					.icon-item-shared {
						color: var(--sfm-target-dialog-share-icon-color);

						@include hover {
							color: var(--sfm-target-dialog-share-icon-hover-color);
						}
					}
					@include hover {
						.icon-item-shared {
							color: var(--sfm-target-dialog-share-icon-hover-color);
						}
					}

					.file-item-icon, .file-item-icon.file-item-image-preview {
						margin-left: 10px;
					}

					.file-item-category, .file-item-size, .file-item-date {
						display: none;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				[data-action='share-info'] {
					pointer-events: none;
				}
			}
		}
	}
}
