@use "sass:math";

$drawer-header-icon-size: 40px;
$drawer-z-index: 7;

.drawer {
	background-color: var(--body-background-color);
	z-index: $drawer-z-index;
	display: flex;
	width: $drawer-width;
	height: 100%;
	flex-direction: column;
	flex-shrink: 0;
	box-sizing: border-box;
	overflow: hidden;

	.drawer-header {
		flex-shrink: 0;
		box-sizing: border-box;
		min-height: 60px;
		padding: 0 16px 4px;
	}

	.drawer-content {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		// Prevent items wrapping when the scrollbar appears after the
		// navigation height was reduced.
		scrollbar-gutter: stable;
	}

	.drawer-content::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	.drawer-content::-webkit-scrollbar:vertical {
		width: 11px;
	}

	.drawer-content::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid var(--body-background-color);
		background-color: rgba(0, 0, 0, .5);
	}
}

.drawer-scrim {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: $drawer-z-index - 1;
}

@include non_desktop {

	.drawer {
		left: 0;
		right: initial;
		display: none;
		position: fixed;
		box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);

		&.drawer-open {
			display: flex;
		}

		+ .drawer-scrim {
			background-color: rgba(0, 0, 0, 0.32);
		}
	}

	.drawer-open + .drawer-scrim {
		display: block;
	}

	.drawer-opened {
		overflow: hidden;
	}

	.drawer-opened .drawer {
		top: 0;
		display: flex;

		.drawer-header {
			height: $header-height;
			padding: 0 16px;

			.burger-menu-button {
				margin-top: math.div($header-height - $drawer-header-icon-size, 2);
				margin-left: -10px;
				width: $drawer-header-icon-size;
				height: $drawer-header-icon-size;
				line-height: $drawer-header-icon-size;
				text-align: center;
				color: var(--navigation-icon-color);
				cursor: pointer;
			}

			@include mobile {
				padding: 0 $header-footer-hor-padding;
			}

			@include mobile_small {
				.burger-menu-button {
					margin-top: 10px;
				}
			}
		}

		+ .drawer-scrim {
			display: block;
		}
	}
}

@include desktop {
	.drawer {
		z-index: auto;
		height: auto;
		position: fixed;
		top: $header-height;
		bottom: 0;

		.drawer-header {
			display: none;
		}

		.drawer-content {
			padding-left: var(--gap-s);
			padding-right: var(--gap-s);
		}
	}
}

@include print {
	.drawer {
		display: none;
	}
}
