.shareupload {
		list-header {
			list-item {
				.shareupload-name {
					flex: 1;
				}
			}
		}

	@include error-or-empty-view {
		.flx {
			position: relative;
		}
	};

	height: 100%; /* to make it vertically content-centered */

	.shareuploads-header .shareuploads-toolbar {
		display: flex;
		margin-top: 7px;

		.file-type-count {
			font-weight: 600;
		}
	}

	.shareuploads-body {
		@import "shareuploadListView";
	}
}
