/* header height adjustment for global navigation */
$header-height: 64px;
/* header/footer padding adjustment for global navigation */
$header-footer-hor-padding: 24px;

$buttonBorderRadius: 8px;
$buttonBorderWidth: 2px;

$checkboxBorderRadius: 2px;
$editorBorderRadius: 8px;
$formBorderRadius: 12px;

$messageLinkDecoration: underline;
$pdfviewToolbarInsetBorder: 1px;
$radioDotDiameter: 8px;

:root {
	--attention2: #EF8300;
	--error: #c80a00;
	--error2: #ff4b51;
	--information1: #54c1ff;
	--primary: #003D8F;
	--secondary: #555555;
	--secondary1: #f9f9f9;
	--secondary-150: #ebebeb;
	--secondary2: #dddddd;
	--secondary-250: #cccccc;
	--secondary4: #888888;
	--secondary-450: #6e6e6e;
	--secondary-550: #3b3b3b;
	--secondary-600: #212121;
	--success: #0fa954;
	--success2: #68ea5d;
	--white: #ffffff;
}

:root{
	--app-link-separator-color: #bbb;
	--backup-files-background: #eee;
	--backup-files-button-active-background-color: var(--secondary);
	--backup-files-button-active-border-color: var(--secondary);
	--backup-files-button-background-color: #666;
	--backup-files-button-border-color: #666;
	--backup-files-button-color: #fff;
	--backup-files-button-hover-background-color: #777;
	--backup-files-button-hover-border-color: #777;
	--backup-files-toolbar-item-active-color: #555;
	--backup-files-toolbar-item-color: #999;
	--backup-files-toolbar-item-hover-color: #555;
	--body-alternative-text-color: #fff;
	--body-background-color: #fff;
	--body-text-color: #333;
	--body-text-color-light: #777;
	--breadcrumb-anchor-color: var(--body-text-color);
	--breadcrumb-bg-color: var(--body-background-color);
	--breadcrumb-link-active-color: var(--link-active-color);
	--breadcrumb-link-color: var(--link-color);
	--breadcrumb-link-hover-color: var(--link-hover-color);
	--breadcrumb-separator-color: #999;
	--breadcrumb-text-color: var(--body-text-color);
	--button-alternative-active-background-color: rgba(255, 255, 255, .25);
	--button-alternative-active-border-color: transparent;
	--button-alternative-active-color: #fff;
	--button-alternative-background-color: transparent;
	--button-alternative-border-color: #fff;
	--button-alternative-color: #fff;
	--button-alternative-disabled-background-color: transparent;
	--button-alternative-disabled-border-color: rgba(255, 255, 255, .25);
	--button-alternative-disabled-color: rgba(255, 255, 255, .25);
	--button-alternative-hover-background-color: #fff;
	--button-alternative-hover-border-color: #fff;
	--button-alternative-hover-color: #003D8F;
	--button-icon-only-selected-background-color: transparent;
	--button-icon-only-selected-border-color: #95A0B0;
	--button-icon-only-selected-icon-color: #95A0B0;
	--button-primary-active-background-color: #0B3476;
	--button-primary-active-border-color: #0B3476;
	--button-primary-background-color: #003D8F;
	--button-primary-border-color: #003D8F;
	--button-primary-color: #fff;
	--button-primary-disabled-background-color: #E6E6E6;
	--button-primary-disabled-border-color: #E6E6E6;
	--button-primary-disabled-color: #B3B6B8;
	--button-primary-hover-background-color: #0674B2;
	--button-primary-hover-border-color: #0674B2;
	--button-primary-hover-color: var(--white);
	--button-secondary-active-background-color: #0B3476;
	--button-secondary-active-border-color: #0B3476;
	--button-secondary-active-color: #fff;
	--button-secondary-background-color: #fff;
	--button-secondary-border-color: #003D8F;
	--button-secondary-color: #003D8F;
	--button-secondary-disabled-background-color: transparent;
	--button-secondary-disabled-border-color: #B3B6B8;
	--button-secondary-disabled-color: #B3B6B8;
	--button-primary-disabled-opacity: 1;
	--button-secondary-hover-background-color: #0674B2;
	--button-secondary-hover-border-color: #0674B2;
	--button-secondary-hover-color: #fff;
	--checkbox-background-color: #fff;
	--checkbox-border-color: #ccc;
	--checkbox-checked-background-color: #fff;
	--checkbox-checked-border-color: #ccc;
	--checkbox-checked-color: #22C646;
	--checkbox-disabled-background-color: #f3f3f3;
	--checkbox-disabled-icon-color: #ccc;
	--checkbox-disabled-text-color: #999;
	--checkbox-error-color: #c00;
	--delete-icon-color: #999;
	--dropdown-arrow-color: #ccc;
	--dropdown-background-color: #fff;
	--dropdown-border-color: #ccc;
	--dropdown-disabled-arrow-color: #999;
	--dropdown-disabled-background-color: #eee;
	--dropdown-disabled-border-color: #ccc;
	--dropdown-disabled-text-color: #999;
	--dropdown-focused-arrow-color: #11C7E6;
	--dropdown-focused-border-color: #999;
	--dropdown-hover-arrow-color: #11C7E6;
	--dropdown-hover-border-color: #999;
	--dropdown-text-color: var(--body-text-color);
	--drop-up-down-flyout-active-color: #11C7E6;
	--drop-up-down-flyout-hover-color: #11C7E6;
	--drop-up-down-flyout-icon-open-color: #11C7E6;
	--drop-up-down-flyout-normal-color: #666;
	--editor-focused-icon-color: var(--button-secondary-color);
	--editor-icon-color: var(--button-secondary-color);
	--editor-placeholder-color: #718095;
	--editor-selection-background-color: #11C7E6;
	--editor-selection-color: #fff;
	--editor-text-color: var(--body-text-color);
	--editor-validation-busy-icon-color: #999;
	--editor-validation-error-icon-color: #D60600;
	--edit-panel-background-color: #f8f8f8;
	--edit-section-edit-background: #eee;
	--empty-item-color: #999;
	--feedback-rating-active-color: #11C7E6;
	--feedback-rating-color: #bbb;
	--feedback-textarea-border-color: #ccc;
	--feedback-text-color: var(--body-text-color);
	--feedback-text-placeholder-color: #718095;
	--flyout-background-color: var(--menu-background-color);
	--flyout-border-color: var(--menu-border-color);
	--flyout-link-active-background-color: var(--menu-item-active-background-color);
	--flyout-link-active-color: var(--menu-item-active-color);
	--flyout-link-color: var(--menu-item-color);
	--flyout-link-hover-background-color: var(--menu-item-hover-background-color);
	--flyout-link-hover-color: var(--menu-item-hover-color);
	--flyout-link-selected-background-color: #f3f3f3;
	--flyout-link-selected-border-color: #11C7E6;
	--flyout-link-selected-color: var(--body-text-color);
	--flyout-ruler-color: var(--flyout-border-color);
	--flyout-shadow: var(--menu-shadow);
	--fm-drag-shadow-symbol-background-color: #fff;
	--fm-drag-shadow-symbol-border: 1px solid #999;
	--fm-drag-shadow-text-color: #999;
	--fm-drop-bg: #F8F8F8;
	--fm-drop-border-color: #BCBCBC;
	--fm-empty-invitation-border-color: #BBB;
	--fm-empty-invitation-font-color: #CCC;
	--fm-empty-item-font-color: var(--empty-item-color);
	--fm-header-bg-color: var(--body-background-color);
	--fm-item-grid-bg: transparent;
	--fm-item-grid-color: var(--body-text-color);
	--fm-item-grid-highlight-bg: #e0e4eb;
	--fm-item-grid-hover-bg: var(--secondary-150);
	--fm-item-grid-hover-color: #11C7E6;
	--fm-item-grid-hover-icon-color: #bbb;
	--fm-item-grid-icon-color: #ccc;
	--fm-item-grid-icon-color-thumbnail: #fff;
	--fm-item-grid-icon-hover-color: #11C7E6;
	--fm-item-grid-icon-selected-color: #aaa;
	--fm-item-grid-select-bg: rgba(255, 255, 255, .2);
	--fm-item-grid-select-color: rgba(0, 0, 0, .15);
	--fm-item-grid-selected-bg: var(--secondary2);
	--fm-item-grid-selected-select-bg: #11C7E6;
	--fm-item-grid-selected-select-color: #fff;
	--fm-item-grid-selecticon-bg: rgba(0, 0, 0, .3);
	--fm-item-grid-thumbnail-bg: rgba(0, 0, 0, .1);
	--fm-item-grid-thumbnail-label-bg: #000;
	--fm-item-grid-thumbnail-label-color: #fff;
	--fm-item-list-bg: var(--list-bg-color);
	--fm-item-list-border: var(--list-item-border);
	--fm-item-list-color: var(--body-text-color);
	--fm-item-list-disabled-color: var(--list-header-color);
	--fm-item-list-header-color: var(--list-header-color);
	--fm-item-list-hover-bg: var(--secondary-150);
	--fm-item-list-hover-color: #11C7E6;
	--fm-item-list-hover-icon-color: #bbb;
	--fm-item-list-icon-color: #ccc;
	--fm-item-list-icon-hover-color: #11C7E6;
	--fm-item-list-icon-selected-color: #aaa;
	--fm-item-list-select-color: #000;
	--fm-item-list-selected-bg: var(--secondary2);
	--fm-item-list-select-hover-color: #11C7E6;
	--fm-item-list-thumbnail-bg: #EEE;
	--fm-item-list-thumbnail-hover-bg: #DDD;
	--fm-item-preview-icon-background-color: rgba(255, 255, 255, .7);
	--fm-sort-flyout-sort-indicator-hover-color: rgba(255, 255, 255, .5);
	--fm-sort-flyout-sort-indicator-next-hover-color: #FFF;
	--fm-sort-flyout-sort-indicator-selected-current-color: #11C7E6;
	--fm-sort-flyout-sort-indicator-selected-next-color: #999;
	--fm-toolbar-item-active-color: var(--link-active-color);
	--fm-toolbar-item-color: #666;
	--fm-toolbar-item-hover-color: var(--link-hover-color);
	--fm-toolbar-item-selected-color: var(--link-selected-color);
	--fm-toolbar-separator-color: #CCC;
	--fm-view-mode-selected-color: var(--link-icon-selected-color);
	--fm-view-mode-tile-border-color: #666;
	--fm-view-mode-tile-color: #666;
	--form-background-color: #eee;
	--form-input-not-set-color: #ccc;
	--form-label-disabled-color: #999;
	--form-message-error-color: #D60600;
	--form-message-success-color: #22C646;
	--fv-button-background-color: var(--secondary-550);
	--fv-button-border-color: var(--secondary4);
	--fv-button-text-color: var(--secondary1);
	--fv-button-hover-background-color: var(--secondary);
	--fv-button-hover-border-color: var(--secondary);
	--fv-button-hover-text-color: var(--secondary1);
	--fv-button-active-background-color: var(--secondary-450);
	--fv-button-active-border-color: var(--secondary-450);
	--fv-button-active-text-color: var(--secondary1);
	--fv-detail-background-color: #111;
	--fv-detail-header-color: var(--body-alternative-text-color);
	--fv-detail-sub-header-color: #999;
	--fv-detail-text-color: var(--body-alternative-text-color);
	--fv-link-active-color: var(--link-alternative-active-color);
	--fv-link-color: var(--link-alternative-color);
	--fv-link-hover-color: var(--link-alternative-hover-color);
	--fv-loading-icon-color: var(--loading-icon-color);
	--fv-overlay-background: #333;
	--fv-overlay-close-active-color: var(--fv-link-active-color);
	--fv-overlay-close-color: var(--fv-link-color);
	--fv-overlay-close-hover-color: var(--fv-link-hover-color);
	--fv-overlay-text-color: #999;
	--fv-status-background-color: #222;
	--fv-status-progress-bar-background-color: #999;
	--fv-status-progress-bar-handle-border: #ccc;
	--fv-status-progress-bar-handle-color: #ccc;
	--fv-status-progress-bar-progress-color: #07b;
	--fv-status-progress-bar-text-color: #999;
	--h1-color: #1F282D;
	--h2-color: #1F282D;
	--h3-color: #1F282D;
	--h4-color: #1F282D;
	--hdshare-footer-background-color: var(--sfm-footer-background-color);
	--hdshare-footer-link-active-color: var(--sfm-footer-link-active-color);
	--hdshare-footer-link-active-underline-color: var(--sfm-footer-link-active-underline-color);
	--hdshare-footer-link-color: var(--sfm-footer-link-color);
	--hdshare-footer-link-hover-color: var(--sfm-footer-link-hover-color);
	--hdshare-footer-link-hover-underline-color: var(--sfm-footer-link-hover-underline-color);
	--hdshare-footer-separator-border-left-color: var(--sfm-footer-separator-border-left-color);
	--hdshare-header-background-color: var(--sfm-header-tools-background-color);
	--hdshare-header-link-active-color: rgba(255, 255, 255, .85);
	--hdshare-header-link-color: #fff;
	--hdshare-header-link-hover-color: rgba(255, 255, 255, .7);
	--hdshare-loading-icon-color: var(--loading-icon-color);
	--icon-checked-color: #22C646;
	--icon-default-color: #999;
	--icon-highlight-color: #11c7e6;
	--icon-muted-color: #d6d6d6;
	--icon-radio-color: #999;
	--icon-radio-selected-color: #003D8F;
	--icon-search-clear-input-color: #555;
	--icon-unchecked-color: #CDCDCD;
	--info-tooltip-icon-active-color: #0B3476;
	--info-tooltip-icon-color: #003D8F;
	--info-tooltip-icon-hover-color: #0674B2;
	--level-up-color: #28CCE8;
	--level-up-disabled-color: var(--body-text-color);
	--link-active-color: #0B70BD;
	--link-alternative-active-color: #EEE;
	--link-alternative-color: #999;
	--link-alternative-hover-color: #DDD;
	--link-color: #0B9DCC;
	--link-hover-color: #28CCE8;
	--link-icon-active-color: var(--link-active-color);
	--link-icon-color: var(--link-color);
	--link-icon-hover-color: var(--link-hover-color);
	--link-icon-selected-color: #11C7E6;
	--link-selected-color: #11C7E6;
	--list-bg-color: var(--body-background-color);
	--list-header-color: #646E80;
	--list-header-hover-color: #11C7E6;
	--list-item-border: 1px dotted #C1CAD6;
	--list-item-expired-background-color: #EEE;
	--list-item-icon-color: var(--body-text-color);
	--list-item-icon-color-stronger: #666;
	--list-item-invalid-background-color: #ffefe5;
	--loading-icon-color: #CCC;
	--loading-icon-font-size: 50px;
	--menu-background-color: #fff;
	--menu-border-color: #ccc;
	--menu-item-active-background-color: #11C7E6;
	--menu-item-active-color: rgba(255, 255, 255, .7);
	--menu-item-color: var(--body-text-color);
	--menu-item-disabled-color: #ccc;
	--menu-item-hover-background-color: #11C7E6;
	--menu-item-hover-color: #fff;
	--menu-separator-color: #ccc;
	--menu-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
	--message-attention-border-color: var(--attention2);
	--message-background-color: var(--secondary1);
	--message-info-border-color: var(--primary);
	--message-negative-border-color: var(--error);
	--message-positive-border-color: var(--success);
	--message-text-color: var(--body-text-color);
	--mouse-selector-border-color: #666;
	--navigation-active-background-color: #eee;
	--navigation-hover-background-color: #eee;
	--navigation-icon-color: #bbb;
	--navigation-menu-item-color: var(--menu-item-color);
	--navigation-menu-item-selected-color: #11C7E6;
	--navigation-separator-color: #bbb;
	--oauth2-logo-color: #003d8f;
	--office-overlay-content-background-color: #ddd;
	--office-overlay-header-background-color: #333;
	--overlay-border-color: #fff;
	--overlay-close-icon-active-color: #0B70BD;
	--overlay-close-icon-color: var(--body-text-color);
	--overlay-close-icon-hover-color: #28CCE8;
	--overlay-close-icon-on-dark-background-active-color: #777;
	--overlay-close-icon-on-dark-background-color: #fff;
	--overlay-close-icon-on-dark-background-hover-color: #ccc;
	--overlay-close-icon-on-white-background-active-color: #0B70BD;
	--overlay-close-icon-on-white-background-color: #666;
	--overlay-close-icon-on-white-background-hover-color: #28CCE8;
	--overlay-close-white-background-color: rgba(255,255,255,.5);
	--overlay-content-color: #fff;
	--overlay-cover-color: rgba(0, 0, 0, .7);
	--overlay-error-background-color: #fff;
	--overlay-error-icon-color: #D60600;
	--overlay-loading-cover-color: rgba(255, 255, 255, 0.5);
	--overlay-loading-icon-color: var(--loading-icon-color);
	--overlay-title-color: var(--body-text-color);
	--password-strength-medium-background-color: #fb1;
	--password-strength-none-background-color: #aaa;
	--password-strength-strong-background-color: #6b0;
	--password-strength-very-weak-background-color: #c00;
	--password-strength-weak-background-color: #e70;
	--pdfview-toolbar-background-color: #fff;
	--pdfview-toolbar-color: #000;
	--pdfview-toolbar-shadow-color: #ccc;
	--profile-ssh-key-list-separator: #000;
	--profile-ssh-key-upload-border: 2px dashed #ddd;
	--protocol-path-overlay-list-background-color: #eee;
	--protocol-path-overlay-list-border: 1px solid #ccc;
	--quota-available-background-color: transparent;
	--quota-bar-background-color: #eee;
	--quota-segment-separator-color: #fff;
	--quota-used-by-device-backups-background-color: #87E2F2;
	--quota-used-by-files-background-color: #003D8F;
	--quota-used-by-snapshots-background-color: #11C7E6;
	--radiobox-background-color: #fff;
	--radiobox-border-color: #ccc;
	--radiobox-checked-background-color: #11C7E6;
	--radiobox-checked-border-color: #11C7E6;
	--radiobox-checked-color: #fff;
	--sales-link-active-background-color: rgba(255,255,255,0.75);
	--sales-link-active-border-color: rgba(255,255,255,0.75);
	--sales-link-active-color: #003D8F;
	--sales-link-border-color: #fff;
	--sales-link-color: #fff;
	--sales-link-hover-background-color: #fff;
	--sales-link-hover-border-color: #fff;
	--sales-link-hover-color: #003D8F;
	--scroll-top-button-icon: url("client/icons/Up_Scroll_Button_light.svg");
	--search-menu-border-color: #555555;
	--search-menu-button-active-border-color: #11C7E6;
	--search-menu-button-background-color: transparent;
	--search-menu-button-background-hover-color: #888888;
	--search-menu-button-border-color: #555555;
	--search-menu-button-border-left-color: #888888;
	--search-menu-button-color: #555555;
	--search-menu-button-selected-background-color: #555555;
	--search-menu-caret-down-color: #555555;
	--search-menu-caret-down-selected-color: #fff;
	--search-menu-checkbox-active-color: #fff;
	--search-menu-checkbox-background-color: #11C7E6;
	--search-menu-checkbox-checked-color: #fff;
	--search-menu-checkbox-color: #DDDDDD;
	--search-menu-checkbox-hover-color: #11C7E6;
	--search-menu-clear-button-background-color: #555555;
	--search-menu-clear-button-border-color: #555555;
	--search-menu-clear-button-color: #fff;
	--search-menu-clear-button-hover-background-color: #888888;
	--search-menu-label-active-background-color: #11C7E6;
	--search-menu-label-active-color: #fff;
	--search-menu-label-color: #555555;
	--search-menu-label-hover-background-color: rgba(17, 199, 230, 0.1);
	--search-menu-label-hover-color: #555555;
	--search-menu-selected-button-color: #fff;
	--setup-step-active-color: #3c9ddb;
	--setup-step-in-active-color: #ccc;
	--setup-success-icon-color: #22C646;
	--sfm-filemanager-background: var(--body-background-color);
	--sfm-footer-background-color: #F6F7F9;
	--sfm-footer-extra-feedback-active-background-color: #0674B2;
	--sfm-footer-extra-feedback-background-color: #0B9DCC;
	--sfm-footer-extra-feedback-color: #fff;
	--sfm-footer-extra-feedback-hover-background-color: #28CCE8;
	--sfm-footer-link-active-color: #666;
	--sfm-footer-link-active-underline-color: #11C7E6;
	--sfm-footer-link-color: #666;
	--sfm-footer-link-hover-color: var(--body-text-color);
	--sfm-footer-link-hover-underline-color: #11C7E6;
	--sfm-footer-separator-border-left-color: #ccc;
	--sfm-header-color: #003D8F;
	--sfm-header-link-active-color: rgba(224, 228, 235, .85);
	--sfm-header-link-color: #E0E4EB;
	--sfm-header-link-hover-color: rgba(224, 228, 235, .7);
	--sfm-header-text-color: #E0E4EB;
	--sfm-main-section-separator-line-style: 1px dotted #bbb;
	--sfm-protocols-header-text-color: #999;
	--sfm-target-dialog-share-icon-color: #ddd;
	--sfm-target-dialog-share-icon-hover-color: #ccc;
	--sfm-upload-detail-overlay-data-current-amount-color: #000;
	--sfm-upload-detail-overlay-data-progress-amount-color: #666;
	--sfm-upload-detail-overlay-filename-color: var(--body-text-color);
	--sfm-upload-detail-overlay-item-icon-cancelled-color: #D60600;
	--sfm-upload-detail-overlay-item-icon-complete-color: #22C646;
	--sfm-upload-detail-overlay-list-background: #fff;
	--sfm-upload-detail-overlay-list-border-color: #BBB;
	--sfm-upload-detail-overlay-progress-complete-background: #EEE;
	--sfm-upload-detail-overlay-progress-empty-background: #fff;
	--sfm-upload-detail-overlay-progress-failed-background: #ffefe5;
	--sfm-upload-detail-overlay-progress-running-background: #CCE4F1;
	--sfm-upload-status-action-icon-color: var(--secondary);
	--sfm-upload-status-background: var(--secondary1);
	--sfm-upload-status-complete-color: var(--success);
	--sfm-upload-status-error-color: var(--error);
	--sfm-upload-status-icon-color: var(--white);
	--sfm-upload-status-loading-color: var(--primary);
	--sfm-upload-status-progress-bar-color: var(--secondary-250);
	--sfm-upload-status-progress-bar-loading-color: var(--primary);
	--sfm-upload-text-color: var(--secondary);
	--sharemanager-footer-delete-all-active-color: var(--link-active-color);
	--sharemanager-footer-delete-all-color: var(--link-color);
	--sharemanager-footer-delete-all-hover-color: var(--link-hover-color);
	--sharemanager-footer-delete-all-icon-active-color: var(--link-active-color);
	--sharemanager-footer-delete-all-icon-color: var(--link-color);
	--sharemanager-footer-delete-all-icon-hover-color: var(--link-hover-color);
	--share-overlay-expired-text-color: #999;
	--share-overlay-link-active-color: var(--link-active-color);
	--share-overlay-link-color: var(--link-color);
	--share-overlay-link-hover-color: var(--link-hover-color);
	--share-overlay-link-icon-active-color: var(--link-active-color);
	--share-overlay-link-icon-color: var(--link-color);
	--share-overlay-link-icon-hover-color: var(--link-hover-color);
	--share-overlay-link-separator-color: var(--fm-toolbar-separator-color);
	--share-upload-progress-background-color: #dfeef8;
	--share-upload-progress-color: #003D8F;
	--slider-pager-color: #cccccc;
	--slider-pager-hover-color: #003D8F;
	--slider-pager-selected-color: #003D8F;
	--slideshow-background-color: var(--overlay-close-white-background-color);
	--slideshow-button-active-color: var(--overlay-close-icon-on-white-background-active-color);
	--slideshow-button-color: var(--overlay-close-icon-on-white-background-color);
	--slideshow-button-hover-color: var(--overlay-close-icon-on-white-background-hover-color);
	--snapshot-list-item-hover-bg: #eee;
	--status-item-color: #999;
	--tag-background-color: #eee;
	--tag-editor-color: var(--body-text-color);
	--tag-focussed-background-color: #c6c6c6;
	--tag-focussed-remove-color: #999;
	--tag-focussed-remove-hover-color: #28CCE8;
	--tag-hover-background-color: #ddd;
	--tag-hover-text-color: var(--tag-text-color);
	--tag-remove-color: #999;
	--tag-remove-hover-color: #28CCE8;
	--tag-text-color: var(--body-text-color);
	--text-editor-background-color: #fff;
	--text-editor-border-color: #ccc;
	--text-editor-disabled-background-color: #eee;
	--text-editor-disabled-color: #999;
	--text-editor-error-border-color: #D60600;
	--text-editor-error-focused-shadow: 0 0 8px rgba(214, 6, 0, 0.6);
	--text-editor-focused-border-color: #11C7E6;
	--text-editor-focused-shadow: 0 0 8px rgba(15, 149, 252, 0.6);
	--text-editor-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
	--thumbnail-loader-icon-color: var(--loading-icon-color);
	--toggle-checked-color: #fff;
	--toggle-checked-span-after-background-color-1: #f3f3f3;
	--toggle-checked-span-after-background-color-2: #fff;
	--toggle-checked-span-after-border-color: #ddd;
	--toggle-checked-span-before-background-color: #22C646;
	--toggle-checked-span-before-border-color: #22C646;
	--toggle-disabled-span-after-background-color: #f3f3f3;
	--toggle-disabled-span-after-border-color: #ddd;
	--toggle-disabled-span-before-background-color: #f3f3f3;
	--toggle-disabled-span-before-color: #ccc;
	--toggle-shadow-color: rgba(0, 0, 0, .15);
	--toggle-span-after-background-color-1: #f3f3f3;
	--toggle-span-after-background-color-2: #fff;
	--toggle-span-after-border-color: #ddd;
	--toggle-span-before-background-color: #fff;
	--toggle-span-before-border-color: #ddd;
	--toggle-span-before-color: #333;
	--tooltip-background-color: #fff;
	--tooltip-border-color: #ddd;
	--tooltip-shadow-color: rgba(0, 0, 0, .1);
	--tooltip-tip-after-color: #fff;
	--tooltip-tip-before-color: #ddd;
	--two-fa-hint-color: #999;
	--upsell-dialog-background-color: var(--body-background-color);
	--upsell-dialog-button-linked-active-color: var(--link-active-color);
	--upsell-dialog-button-linked-color: var(--upsell-topbar-text-color);
	--upsell-dialog-button-linked-hover-color: var(--link-hover-color);
	--upsell-dialog-button-primary-active-background-color: var(--button-primary-active-background-color);
	--upsell-dialog-button-primary-active-border-color: var(--button-primary-active-border-color);
	--upsell-dialog-button-primary-background-color: var(--button-primary-background-color);
	--upsell-dialog-button-primary-border-color: var(--button-primary-border-color);
	--upsell-dialog-button-primary-color: var(--button-primary-color);
	--upsell-dialog-button-primary-hover-background-color: var(--button-primary-hover-background-color);
	--upsell-dialog-button-primary-hover-border-color: var(--button-primary-hover-border-color);
	--upsell-dialog-check-icon: var(--icon-checked-color);
	--upsell-dialog-text-color: var(--body-text-color);
	--upsell-teaser-action-background-color: var(--body-background-color);
	--upsell-teaser-action-border-color: #CCC;
	--upsell-teaser-background-color: #F9F9F9;
	--upsell-topbar-background-color: #eee;
	--upsell-topbar-hide-link-active-color: var(--link-active-color);
	--upsell-topbar-hide-link-color: var(--upsell-topbar-text-color);
	--upsell-topbar-hide-link-hover-color: var(--link-hover-color);
	--upsell-topbar-text-color: #333;
	--wait-overlay-loading-icon-color: var(--loading-icon-color);
	--warning-icon-color: #eecc55;
}
