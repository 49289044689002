@use "sass:math";

$slideshow-navigation-button-height: 40px;
$slideshow-navigation-button-width: 40px;
$slideshow-text-side-padding: 32px;

slide-show {
	display: flex;
	flex-direction: column;

	.slider {
		overflow: hidden;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		// Set preserve aspect ration 2:1 - otherwise the SVG will not be displayed
		padding-top: 50%;
		width: 100%;
		display: block;

		button {
			z-index: 4;
			width: $slideshow-navigation-button-width;
			height: $slideshow-navigation-button-height;

			cursor: pointer;
			position: absolute;
			top: 50%;
			margin-top: -1 * math.div($slideshow-navigation-button-height, 2);
			user-select: none;
			border-radius: 50%;
			border: none;
			font-weight: bold;
			color: var(--slideshow-button-color);
			background-color: var(--slideshow-background-color);


			@include hover {
				color: var(--slideshow-button-hover-color);
			}

			@include active {
				color: var(--slideshow-button-active-color);
			}

			&.previous {
				left: 10px;
				padding: 0 8px 0 4px;
			}

			&.next {
				right: 10px;
				padding: 0 6px 0 8px;
			}
		}
	}


	page-control {
		margin-top: 5px;
		margin-bottom: 25px;
	}

	h1.caption {
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 25px;
		padding-right: $slideshow-text-side-padding;
		padding-left: $slideshow-text-side-padding;
	}

	.text {
		padding-right: $slideshow-text-side-padding;
		padding-left: $slideshow-text-side-padding;
	}
}
