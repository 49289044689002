.app-start {
	// Make the app fill the whole viewport height
	flex: 1 0 auto;

	display: flex;
	flex-direction: column;

	.content-wrap {
		/* required to limit login form with etc for screens more than 479px */
		align-self: center;

		width: 400px;

		margin-top: $header-height;
		margin-bottom: $footer-height;
	}

	.login-downloads h2 {
		margin: 41px 0 5px;
		padding-top: 40px;
		border-top: 1px dotted var(--navigation-separator-color);
	}

	.app-links {
		margin-bottom: 2px;
	}

	.app-link {
		padding-left: 5px;
	}

	.app-link-separator:before {
		content: '\2502';
		padding-left: 5px;
		color: var(--app-link-separator-color);
	}

	@include mobile {
		.login-downloads h2 {
			margin: 41px 0 2px;
		}
	}

	@include mobile_small {
		.content-wrap {
			width: 100%;
			min-width: 280px;
			max-width: 360px;
			padding: 0 $header-footer-hor-padding;
			box-sizing: border-box;
		}

		.login-downloads h2 {
			margin: 40px 0 0;
		}
	}

	&.is-loading {
		> .common-loader {
			display: flex;
			z-index: 10; // has to be above footer and header
			background-color: var(--overlay-cover-color);
		}
	}

	> .common-loader {
		display: none;
	}
}

.ownerselect {
	&:not(.noselection) .noselection-message {
		display: none;
	}

	h1 {
		margin: 50px 0 40px;
	}

	h4 {
		margin: 20px 10px 15px;
		display: inline-block;
		color: var(--list-header-color);
	}

	p {
		margin-bottom: 30px;
	}

	li {
		padding: 20px 20px 19px;
		border-bottom: 1px dotted var(--radiobox-border-color);
		margin: 0;

		&:first-child {
			border-top: 1px dotted var(--radiobox-border-color);
			padding-top: 19px;
		}

		&.create-account {
			padding-top: 17px;
			padding-bottom: 18px;

			.sj-radio-text {
				padding: 2px 5px;
				background-color: #E0E4EB;
			}
		}
	}


	// reset message box width, within this view, it should be 100%
	.sj-message {
		width: 100%;
		max-width: inherit;
	}

	.choices {
		margin-bottom: 40px;
	}
}

.hdf-invitation-confirm {
	.invitation-title {
		margin: 48px 0 42px;
		font-weight: 600;
		font-size: 24px;

		@include mobile {
			margin: 42px 0 25px;
		}

		@include mobile_small {
			margin: 39px 0 23px;
		}

	}

	.invitation-hint {
		margin-bottom: 30px;
	}

	.sj-form {
		border-bottom: none;
	}

	.buttons > .sj-button {
		width: 100%;
	}

	.sj-form-group {
		&.recommendation,
		&.alias {
			margin-bottom: $form-group-bottom-margin * 0.5;
		}

		&.password,
		&.password-strength {
			margin-bottom: 10px;
		}
	}

	[name="alias"] {
		text-transform: lowercase;
	}
}

.hide-app-links .login-downloads {
	display: none;
}
