teamfolder-permission-display {
	.sj-form-horizontal {
		width: 100%;

		.sj-form-group {
			max-width: none;
			margin-right: 70px;
			margin-left: 10px;

			padding-top: 0;
			padding-bottom: 5px;

			border-bottom: var(--sfm-main-section-separator-line-style);

			&:last-of-type {
				border: none;
				padding-bottom: 0;
			}

			@include mobile {
				margin-right: 10px;
			}
		}

		.label {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			flex: 1;
		}

		.field {
			flex: 0 0 240px;
		}

		.empty-text {
			flex: 0 0 100%;
			color: var(--empty-item-color);
		}

		.edit-button {
			float: right;
		}
	}
}
