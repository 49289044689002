$shareupload-item-list-icon-size: 40px;

.shareuploads-grid {
	.group {
		width: 40px;
	}

	.file-item-icon {
		width: $shareupload-item-list-icon-size;
		height: $shareupload-item-list-icon-size;

		@include svg-icon-default;
	}

	.itemcontent {
		@extend %item-list-status-background;
	}

	.icon-password-protected, .password-protected-placeholder {
		width: 10px;
		padding: 0 10px;
	}

	.icon-password-protected {
		line-height: 60px;
		vertical-align: middle;
		color: var(--list-item-icon-color-stronger);

		@include hover {
			color: var(--fm-item-list-hover-color);
		}

	}

	.shareupload-name {
		@include display-name-container {
			min-width: 180px;
		}
	}

	.valid .shareupload-name,
	.expired .shareupload-name {
		@include hover {
			cursor: pointer;
			color: var(--fm-item-list-hover-color);

			.file-item-icon {
				@include svg-icon-highlighted;
			}
		}
	}

	.shareupload-uploads-count {
		width: 80px;
		min-width: 50px;
		text-align: right;

		@include mobile_small {
			display: none;
		}
	}

	.shareupload-expiration-time {
		width: 140px;
		min-width: 100px;

		@include mobile() {
			display: none;
		}
	}

	.shareupload-status {
		width: 130px;
		min-width: 80px;

		@include mobile_small() {
			display: none;
		}
	}

	.shareupload-item-button-container {
		@extend %item-list-button-container;
	}
}

