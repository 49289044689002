.sshkey-list {
	flex: 1;
	flex-direction: column;
}

.sshkey-entry {
	display: flex;
	align-items: center;
	width: 100%;
}

.sshkey-entry {
	margin: 5px 0;
}

.sshkey-entry:first-child {
	margin-top: 0;
}

.sshkey-entry:last-child {
	margin-bottom: 0;
}

.sshkey-label {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 320px;
	white-space: nowrap;

	@include mobile {
		width: 200px
	}

	@include mobile_small {
		width: 120px
	}
}

.sshkey-info-link {
	@include link-styled;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-right: auto;
}

.sshkey-separator {
	border-left: 1px solid var(--profile-ssh-key-list-separator);
	height: 13px;
	margin: 5px;
}

.sshkey-entry .sj-button {
	margin-left: 10px;
}

.sshkey-tooltip p {
	word-break: break-word;
	margin: 0 0 10px;
}

.sshkey-upload {
	margin-top: 20px;
	border: var(--profile-ssh-key-upload-border);
	padding: 40px;
	text-align: center;

	.button {
		display: block;
		@extend %link;

		.file-input-wrapper input {
			display: none;
		}
	}
	.loader {
		display: none;

		.icon-loading {
			font-size: 14px;
		}
	}

	&.dragged-over,  {
		background-color: var(--sfm-upload-status-background);
	}

	&.loading {
		background-color: var(--sfm-upload-status-background);

		.button {
			display: none;
		}
		.loader {
			display: block;
		}
	}
}
