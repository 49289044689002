.mailupload-overlay {
	.mailupload-info {
		.description {
			padding: 0 0 30px 0;
			color: var(--body-text-color-light);
		}
		.email-view {
			width: 100%;
		}
		.buttons {
			padding: 30px 0;
		}
		.delete-button,
		.settings-button {
			@include link-styled;
		}
		.delete-button {
			margin-right: 20px;
		}
		.mail-button {
			width: auto;
			text-align: left;

			@include iconButtonPosition();

			.label {
				display: inline-block; /* don't hide the label on small screens */
			}
		}
	}
}
