.mailuploads {
	@include error-or-empty-view {
		.flx {
			position: relative;
		}
	};

	height: 100%; /* to make it vertically content-centered */
}

.mailuploads-header {
	.mailuploads-toolbar {
		display: flex;
		margin-top: 7px;
	}

	.file-type-count {
		font-weight: 600;
	}
}

@import "mailuploadListView";
