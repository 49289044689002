.git-info-overlay {
	.sj-overlay-content {
		width: 440px;
	}

	.overlay-text {
		margin-bottom: 25px;
		margin-top: 0;
	}

	.url {
		display: flex;
		align-items: center;

		.editor {
			flex: 1;
			margin-right: 10px;

			@include placeholder {
				color: var(--editor-placeholder-color);
			}
		}

		.copy-button {
			@include link-styled;
		}
	}

	.close-button {
		margin-top: 30px;
	}
}
