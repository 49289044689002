$share-item-list-icon-size: 40px;

.sh-grid {
	list-header {
		list-item {
			.file-item-displayname {
				flex: 1;
			}
		}

		.file-item-displayname {
			// Extra space for the "password protected" icon
			padding-left: 30px;
		}
	}

	.footer {
		text-align: right;
		padding-top: 20px;
		margin: 0 $fm-dropzone-size;
		@include mobile_small {
			margin: 0;
		}

		.deleteAll {
			cursor: pointer;

			.icon-delete-shares {
				color: var(--sharemanager-footer-delete-all-icon-color);
			}
			.label {
				padding: 0 0 0 10px;
				color: var(--sharemanager-footer-delete-all-color);
				position: relative;
				bottom: 2px;

			}

			@include hover {
				.icon-delete-shares {
					color: var(--sharemanager-footer-delete-all-icon-hover-color);
				}

				.label {
					color: var(--sharemanager-footer-delete-all-hover-color);
					text-decoration: underline;
				}
			}

			@include active {
				.icon-delete-shares {
					color: var(--sharemanager-footer-delete-all-icon-active-color);
				}

				.label {
					color: var(--sharemanager-footer-delete-all-active-color);
					text-decoration: underline;
				}
			}
		}
	}

	.itemcontent {
		@extend %item-list-status-background;
	}


	// rows
	.file-item-icon {
		height: $share-item-list-icon-size;
		width: $share-item-list-icon-size;

		@include svg-icon-default;

		&.file-item-image-preview {
			overflow: hidden;

			position: relative;

			div {
				width: $share-item-list-icon-size;
				height: $share-item-list-icon-size;
				margin-top: 0;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-color: var(--fm-item-list-thumbnail-bg);

				&.max_width, &.max_width_height {
					background-size: $share-item-list-icon-size auto;
				}
				&.max_height {
					background-size: auto $share-item-list-icon-size;
				}
				&.max_small_height {
					background-size: auto 34px;
				}
				&.max_small_width {
					background-size: 34px auto;
				}
			}

			&.icon-mimetype-group-pdf {
				@include pdf-icon-thumb {
					font-weight: bold;
					border-radius: 2px;
					top: 18px;
					padding: 1px 3px;
					font-size: 10px;
					width: 18px;
				}
			}

			&.icon-mimetype-group-video {
				@include video-icon-thumb {
						padding: 0 1px 0 3px;
						width: 20px;
						height: 24px;
						line-height: 24px;
						font-size: 13px;
						border-radius: 15px;
				}
			}
		}
	}

	.file-item-displayname {
		padding-left: 0;
	}

	.icon-password-protected, .password-protected-placeholder {
		width: 10px;
		padding: 0 10px;
	}

	.icon-password-protected {
		line-height: 60px;
		vertical-align: middle;
		color: var(--list-item-icon-color-stronger);

		@include hover {
			color: var(--fm-item-list-hover-color);
		}

	}

	.group {
		width: $share-item-list-icon-size;
	}

	.valid .path,
	.expired .path {
		@include hover {
			cursor: pointer;
			color: var(--fm-item-list-hover-color);

			.file-item-icon:not(.file-item-image-preview) {
				@include svg-icon-highlighted;
			}

			.file-item-image-preview div {
				background-color: var(--fm-item-list-thumbnail-hover-bg);
			}
		}
	}

	.path {
		@include display-name-container {
			min-width: 120px;
		}
	}

	.share-item-count {
		width: 80px;
		text-align: right;

		@include mobile_small {
			display: none;
		}
	}

	.share-item-status {
		width: 100px;
		min-width: 80px;

		@include mobile {
			display: none;
		}
	}

	.share-item-button-container {
		@extend %item-list-button-container;
	}
}

.shares-list-contextmenu {
	// make menu wide enough (even when text is bold)
	min-width: 300px;
}

