.onboarding-dialog {
	.sj-overlay-content {
		width: 600px;
	}

	.sj-overlay-body {
		padding: 0 0 30px;
	}

	@include shimmed-close-button {
		z-index: 5;
	}

	.overlay-buttons {
		margin: 29px 29px 0;
	}
}
