.protocol-display {
	.protocols-table {
		@include mobile {
			display: flex;
		}
	}

	.protocols-table-header,
	.protocols-table-content {
		display: flex;

		border-bottom: var(--sfm-main-section-separator-line-style);

		@include mobile {
			flex-direction: column;

			border-bottom: none;
		}
	}

	.protocols-table-item {
		display: flex;
		flex: 1 1 110px;
		align-items: center;
		justify-content: center;

		height: 60px;

		white-space: nowrap;

		@include mobile {
			flex: 0 0 40px;
			justify-content: flex-start;

			border-bottom: var(--sfm-main-section-separator-line-style);
			&:first-child {
				border-top: var(--sfm-main-section-separator-line-style);
			}
		}
	}

	.protocols-table-header {
		.protocols-table-item {
			height: 30px;

			color: var(--sfm-protocols-header-text-color);

			@include mobile {
				width: 90px;

				padding-left: 10px;

				border-right: var(--sfm-main-section-separator-line-style);
			}
		}
	}

	.protocols-table-content {
		@include mobile {
			flex: 1 1 auto;
		}

		.protocols-table-item {
			@include mobile {
				padding-left: 40px;
			}
		}
	}

	.protocols-table-edit {
		justify-content: flex-end;
		flex: 0 0 $button-height + var(--gap-l);

		@include mobile {
			display: none;
		}
	}

	.paths-wrap {
		margin-top: 10px;
	}

	.protocols-hint {
		margin-top: 24px;
	}
}
