.devicebackup {
	display: flex;
	flex-direction: column;
}
.devicebackup-body {
	@include error-or-empty-view {
		padding-top: var(--gap-l);
	}
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	position: relative;
}

.devicebackups-grid {
	.item-label {
		display: block;
	}

	.item-name {
		flex: 1;

		min-width: 200px;
	}

	.platform-icon {
		display: inline-block;
		width: 40px;
		text-align: center;
		vertical-align: middle;

		.icon {
			line-height: 60px;
		}
	}

	.item-date {
		width: 140px;
	}

	.running .item-date {
		color: var(--fm-item-list-disabled-color);
	}

	.item-scope {
		width: 220px;
	}

	.item-size {
		width: 120px;
	}

	.item-button-container {
		@extend %item-list-button-container;
	}

	@include portable() {
		.item-date,
		.item-size {
			flex-shrink: 1;
		}

		.item-scope {
			flex-shrink: 2;
		}
	}

	@include tablet_large() {
		.item-size {
			flex-shrink: 2;
		}

		.item-scope {
			display: none;
		}
	}

	@include mobile() {
		.item-name {
			min-width: 150px;
		}

		.item-size {
			display: none;
		}
	}

	@include mobile_small() {
		.item-name {
			min-width: 0;
		}

		.item-date {
			display: none;
		}
	}
}

.devicebackup {
	.meta {
		display: flex;
		justify-content: flex-end;
		margin-right: var(--gap-s);
		margin-left: var(--gap-s);
		margin-top: var(--gap-l);

		@include mobile_small() {
			margin-top: var(--gap-s);
		}

		.icon-database {
			margin-right: var(--gap-xs);
			margin-top: 2px;
			color: var(--device-backups-info-color);
		}

		.total-size {
			color: var(--device-backups-info-color);
		}
	}
}

.is-empty .meta {
	display: none;
}

.devicebackup-error {
	.sj-message {
		margin-top: 20px;
		width: 100%;
		max-width: none;
	}
}
