$form-horizontal-label-width: 180px;
$form-horizontal-field-width: 360px;
$form-horizontal-field-height: 39px;

.sj-form {

	.sj-form-group {
		display: block;
		margin-bottom: $form-group-bottom-margin;
	}

	.sj-form-group.error .sj-label {
		color: var(--form-message-error-color);
		font-weight: 700;
	}

	.buttons {
		margin-top: 36px;
	}

	.sj-editor,
	.sj-simple-dropdown {
		display: block;
		margin-top: 10px;
	}

	& .sj-label {
		display: block;
	}

	& .message {
		margin: 6px 0;

		display: none;

		&.success {
			color: var(--form-message-success-color);
			display: block;
		}

		&.error {
			color: var(--form-message-error-color);
			display: block;
		}
	}

	@include mobile {
		.sj-form-group {
			margin-bottom: 23px;
		}

		.sj-editor,
		.sj-simple-dropdown {
			margin-top: 7px;
		}
	}

	@include mobile_small {
		.sj-form-group {
			margin-bottom: 20px;
		}

		.sj-editor,
		.sj-simple-dropdown {
			margin-top: 5px;
		}
	}

	> label:last-of-type {
		margin-bottom: 0;
	}
}

.sj-form-horizontal {

	max-width: $content-max-width;

	.form-edit-area {
		background-color: var(--form-background-color);
		border-radius: $formBorderRadius;
		padding: 30px;
	}

	.sj-form-group {
		max-width: $form-horizontal-label-width + $form-horizontal-field-width;

		display: flex;
		align-items: center;

		margin-bottom: var(--gap-xl);

		> .label,
		// used as placeholder if no label is used in "row"
		> .label-space {
			display: inline-block;
			box-sizing: border-box;

			width: $form-horizontal-label-width;
			max-width: 100%;

			padding-right: 10px;
		}

		> .field {
			display: flex;
			align-items: center;
			min-height: $form-horizontal-field-height;
			width: $form-horizontal-field-width;
			max-width: 100%;

		}
		.not-set {
			color: var(--form-input-not-set-color);
		}
	}

	&.disabled {
		.sj-form-group {
			> .label {
				color: var(--form-label-disabled-color);
			}

			> .field {
				box-sizing: border-box;
			}
		}
	}

	.sj-editor,
	.sj-simple-dropdown {
		width: 100%;
	}

	@include mobile {
		width: auto;

		.sj-form-group {
			display: block;

			> .label {
				width: auto;
				height: auto;

				padding-right: 0;
			}

			> .label-space {
				display: none;
			}
		}
	}
}

.icon-checked {
	color: var(--icon-checked-color);
}

.icon-unchecked {
	color: var(--icon-unchecked-color);
}
