.edit-panel {
	display: flex;
	flex-direction: column;

	background-color: var(--edit-panel-background-color);
	padding: var(--gap-l) var(--gap-l) var(--gap-xl);
	border-radius: 12px;

	.label {
		display: block;
		margin-bottom: var(--gap-s);
		font-weight: 600;
	}

	.row:not(:last-child) {
		margin-bottom: var(--gap-l);
	}

	.row {
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;

		.label {
			flex: 0 0 100%;
		}

		.sj-toggle {
			flex: 0 0 80px;
		}

		.sj-editor {
			flex: 1 1;
		}

		.sj-checkbox {
			flex: 0 1 90%;
			overflow: hidden;
		}

		> .sj-info-tooltip {
			flex: 0 0;
			padding-left: var(--gap-s);
			padding-right: 0;
		}
	}
}
