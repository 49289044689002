.shareupload-overlay {
	.shareupload-info {
		.expired {
			min-height: 100px;

			display: flex;
			align-items: center;
			justify-content: center;

			p {
				text-align: center;
				color: var(--share-overlay-expired-text-color);
			}
		}
		.preview {
			display: flex;

			.sj-text-editor {
				flex: 1;
				display: block;
				margin-right: 20px;
			}
		}
		.buttons {
			padding: 30px 0;
		}
		.delete-button,
		.settings-button {
			@include link-styled;
		}
		.delete-button {
			margin-right: 20px;
		}
		.mail-button {
			width: auto;
			text-align: left;

			@include iconButtonPosition();

			.label {
				display: inline-block; /* don't hide the label on small screens */
			}
		}
	}
}

.shareupload-overlay,
.shareupload-create-overlay {
	.hint {
		margin-bottom: var(--gap-l);
		color: var(--body-text-color-light);
	}
}
