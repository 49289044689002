.invitation-display {
	max-width: $content-max-width;

	h3 {
		display: flex;
		align-items: center;

		&:not(:first-of-type) {
			margin-top: 80px;
		}
	}

	.display-invitation-message {
		width: 100%;
		max-width: 100%;
	}
}
