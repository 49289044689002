.create-git-overlay {
	.sj-overlay-content {
		width: 380px;
		@include mobile_small {
			width: 100vw;
		}
	}

	.create-git-overlay-body {
		position: relative;

		.repository-name {
			display: flex;
			align-items: center;

			margin-top: 25px;
			margin-bottom: 25px;

			.sj-text-editor {
				flex-grow: 1;

				margin-left: 10px;
				margin-right: 5px;
			}

			.folder-git-icon {
				@include svg-icon-default;
				flex: 0 0 auto;
				width: 40px;
				height: 40px;
			}
		}
	}
}
