.share-overlay-body {
	height: inherit;
	max-width: 100%;

	display: flex;
	flex-direction: column;
	flex: 0 0 100%;

	.share-overlay-content {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
	}

	.share-info,
	.share-edit {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
	}

	.ttl-maxcount-writable .col {
		display: inline-block;
		margin-bottom: 20px;
	}

	.expired {
		min-height: 145px;

		display: flex;
		align-items: center;
		justify-content: center;

		p {
			text-align: center;
			color: var(--share-overlay-expired-text-color);
		}
	}

	.preview {
		display: flex;
		flex-direction: row;

		.sj-text-editor {
			flex: 1;
			display: block;
			margin-right: 20px;
		}

		.preview-btn {
			margin-right: 0;

			.icon {
				top: 1px;
				left: 2px;
			}
		}
	}

	.channels {
		margin-top: 20px;

		// override generic link styles because this one has a href
		a:hover, a:active {
			text-decoration: none;
		}
	}

	// override generic link styles to be conform with overlay styles
	.sj-icon-button {
		width: auto;
		.label {
			display: inline-block;
		}
	}

	.actions {
		font-size: 0; // reduce space between children
		margin: 20px 0 0;

		.sep {
			display: inline-block;
			width: 1px;
			height: 14px;
			margin: 0 10px;
			position: relative;
			top: 1px;
			background: var(--share-overlay-link-separator-color);
		}

		.delete-share, .edit-share {
			cursor: pointer;
			white-space: nowrap;
			line-height: 40px;

			.icon {
				width: 18px;
				text-align: center;
				color: var(--share-overlay-link-icon-color);
			}

			.label {
				position: relative;
				bottom: 1px;
				padding-left: 10px;
				font-size: $base-font-size;
				color: var(--share-overlay-link-color);
			}

			@include hover {
				.icon {
					color: var(--share-overlay-link-icon-hover-color);
				}

				.label {
					color: var(--share-overlay-link-hover-color);
					text-decoration: underline;
				}
			}

			@include active {
				.icon {
					color: var(--share-overlay-link-icon-active-color);
				}
				.label {
					color: var(--share-overlay-link-active-color);
					text-decoration: underline;
				}
			}
		}
	}

	.share-email {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;

		p {
			margin: 10px 0 30px;
		}

		textarea.sharemail-message {
			border-top: 0;
			resize: none;
			width: 100%;
			height: 100%;
			overflow-y: auto;
			border-radius: 0 0 $editorBorderRadius $editorBorderRadius;

			&:focus {
				z-index: 1;
			}

			display: flex;
			flex-direction: column;
			flex: 1 1 auto;

			@include placeholder {
				color: var(--editor-placeholder-color);
			}
		}

		.taglist {
			flex: none;

			max-height: 75px;
			overflow-y: auto;

			input {
				@include placeholder {
					color: var(--editor-placeholder-color);
				}
			}
		}
	}

	.overlay-buttons > * {
		vertical-align: middle;

		@include iconButtonPosition();
	}
}
