.backup-files {
	--button-primary-active-background-color: var(--backup-files-button-active-background-color);
	--button-primary-active-border-color: var(--backup-files-button-active-border-color);
	--button-primary-background-color: var(--backup-files-button-background-color);
	--button-primary-border-color: var(--backup-files-button-border-color);
	--button-primary-color: var(--backup-files-button-color);
	--button-primary-hover-background-color: var(--backup-files-button-hover-background-color);
	--button-primary-hover-border-color: var(--backup-files-button-hover-border-color);
	--fm-toolbar-item-color: var(--backup-files-toolbar-item-color);
	--fm-toolbar-item-hover-color: var(--backup-files-toolbar-item-hover-color);
	--fm-toolbar-item-active-color: var(--backup-files-toolbar-item-active-color);

	.app-files-header {
		background-color: var(--backup-files-background);
		border-top: $header-bottom-space solid var(--body-background-color);
		padding-top: 10px;
		height: $menubar-height;

		@include mobile {
			border-top: 0;
			top: $header-height;
		}
	}

	.toolbar-row {
		margin-top: 20px;
	}

	.filemanager {
		margin-top: calc($menubar-height + var(--gap-xs));

		@include mobile {
			margin-top: calc($menubar-height - var(--gap-s));
		}
	}

	.search-form {
		display: none;
	}

	.fm-folder-flyout {
		filter: grayscale(1);
	}

	$navigating-backups-border-width: 10px;

	.selection-boundary {
		border: $navigating-backups-border-width solid var(--backup-files-background);
		// Only bottom, left and right border is required
		border-top: none;

		@include mobile {
			border-width: $content-left-offset-mobile-small;
			left: -$content-left-offset-mobile-small;
			right: -$content-right-offset-mobile-small;
		}
	}

	x-filemanager {
		margin-top: 0;
		border-width: $navigating-backups-border-width;

		@include mobile {
			border-width: 0;
		}
	}
}

