user-permission-display {
	.sj-form-horizontal {
		width: 100%;

		.sj-form-group {
			max-width: none;
			margin-right: 70px;
			margin-left: 10px;

			padding-bottom: var(--gap-s);

			border-bottom: var(--sfm-main-section-separator-line-style);
			margin-bottom: 0;

			&:not(:first-of-type) {
				padding-top: var(--gap-s);
			}

			&:last-of-type {
				border: none;
				padding-bottom: 0;
			}

			@include mobile {
				margin-right: 10px;
			}

			.label-icon {
				@include svg-icon-default;
				height: 40px;
				width: 40px;

				display: inline-block;
				margin: 0 10px 0 0;
				@include mobile {
					display: none;
				}

				// Explicitly flex for long name
				flex: 0 0 auto;
			}

			.label-text {
				text-overflow: ellipsis;
				overflow: hidden;
			}

			.label {
				margin-right: 10px;
				flex: 1;
				display: flex;
				align-items: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.field {
				flex: 0 0 240px;
			}

			.empty-text {
				flex: 0 0 100%;
				color: var(--empty-item-color);
			}
		}
	}

	.edit-button {
		float: right;
	}
}
