orderable-addons {
	.section {
		margin-bottom: var(--gap-xl);
	}

	h3,
	h4 {
		margin: 0;
	}

	p {
		margin-top: var(--gap-xs);
		margin-bottom: var(--gap-s);
	}

	.addon-list {
		width: 100%;

		border-top: var(--sfm-main-section-separator-line-style);
		border-bottom: var(--sfm-main-section-separator-line-style);
	}

	.addon-separator {
		border-bottom: var(--sfm-main-section-separator-line-style);
	}

	.addon-row {
		.addon-text {
			padding: var(--gap-s) var(--gap-s) 0 0;
		}

		.addon-action {
			// force column to only use the minimum necessary width
			width: 0;

			text-align: center;

			@include desktop {
				padding-right: var(--gap-s);
			}

			.sj-button {
				margin-right: 0;
				width: 100%;
				white-space: nowrap;
			}
		}
	}
}
