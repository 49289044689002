.sj-breadcrumb {

	display: flex;

	overflow: hidden;
	text-overflow: ellipsis;

	background-color: var(--breadcrumb-bg-color);

	font-size: $breadcrumb-font-size;
	font-weight: 700;
	line-height: 23px;

	height: 25px;

	white-space: nowrap;

	.level-up-area, .root {
		flex: 0 0 auto;
	}

	.level-up {
		display: inline-block;
		margin: 0;

		color: var(--level-up-color);

		&.inactive {
			color: var(--level-up-disabled-color);
		}

		&:not(.inactive) {
			cursor: pointer;
		}
	}

	.segment {
		.hellip {
			display: inline-block;
			padding: 0;
		}
	}

	.inner {

		@include flex-fix-width();

		display: flex;

		flex-flow: row-reverse wrap;


		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-lines: multiple;

		flex: 0 1 auto;

		-webkit-box-pack: end;
		-ms-flex-pack: end;

		// Hide breaking items, the breadcrumbs line-height is less than
		// breadcrumbs height. In certain circumstances the masked breadcrumb
		// segments will overlap the level-up arrow.
		overflow: hidden;

		.segment {

			margin-right: -22px;;

			background-color: var(--breadcrumb-bg-color);

			&.current {
				color: var(--breadcrumb-text-color);
				margin-right: 0;
				min-width: 100px;
				flex: 1 2 auto;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			a.text {
				background-color: var(--breadcrumb-bg-color);
			}
			&:not(.current) a.text {
				display: inline-block;
				min-width: 15px;
			}
		}
	}

	.sep {
		display: inline-block;
		border-left: 1px solid var(--breadcrumb-separator-color);
		width: 0px;
		margin: 2px 10px 0 5px;
		height: 20px;
		vertical-align: top;
	}

	.segment {

		a {
			color: var(--breadcrumb-link-color);
			text-decoration: none;
		}

		@include portable() {
			&:not(.current), &.current .hellip, &.current .arrow {
				display: none;
			}
		}

		&.current a {
			color: var(--breadcrumb-anchor-color);
			cursor: default;
		}

	}

	a {

		&.link {

			color: var(--breadcrumb-link-color);

			@include hover {
				color: var(--breadcrumb-link-hover-color);
			}
			@include active {
				color: var(--breadcrumb-link-active-color);
			}

			@include hover {
				text-decoration: none;
				cursor: pointer;
			}
		}

	}

	.root ~ .inner .segment {
		margin-left: 7px;
		@include portable {
			margin: 0;
		}
	}
}
