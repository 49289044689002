.mu-grid {
	.group {
		width: 40px;
	}

	.file-item-icon {
		width: 40px;
		height: 40px;

		@include svg-icon-default;
	}

	.itemcontent {
		@extend %item-list-status-background;
	}

	.mu-name {
		@include display-name-container {
			min-width: 120px;
		}
	}

	.valid .mu-name,
	.expired .mu-name {
		@include hover {
			cursor: pointer;
			color: var(--fm-item-list-hover-color);

			.file-item-icon {
				@include svg-icon-highlighted;
			}
		}
	}

	.mu-status {
		width: 100px;
		min-width: 80px;

		@include mobile_small() {
			display: none;
		}
	}

	.mu-item-button-container {
		@extend %item-list-button-container;
	}
}

