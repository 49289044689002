.user-info-overlay {
	p {
		margin: 0 0 20px;
	}

	.dismiss-button {
		@extend %link;
		padding-bottom: 10px;
	}
}
