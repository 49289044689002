.user-profile {
	.coredata-form {
		width: 100%;

		.sj-form-group {
			max-width: 100%;
		}

		.edit-button {
			float: right;
		}

		.email-notice {
			margin-left: $form-horizontal-label-width;
			@include mobile {
				margin-left: 0;
				width: auto;
			}

			> p {
				// Reduce text run length for desktop
				// to even out the wrapped text.
				width: 400px;
				max-width: 100%;
			}

			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.pending-email {
					flex: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					width: 400px;
					max-width: 100%;
				}
				@include non-mobile {
					align-items: center;
					flex-direction: row;
				}
			}
		}

		.email-group {
			// Use as much space as is available to fit a [re-]send button
			width: auto;

			> .label {
				// Equal vertical alignment when field wrapped before the
				// breakpoint
				padding: 10px 0;
				flex-shrink: 0;
			}

			.field {
				// Use as much space as is available to fit a [re-]send button
				display: flex;
				flex: 1;
				flex-direction: column;
				align-items: flex-start;
				min-width: 120px;

				@include non-mobile {
					align-items: center;
					flex-direction: row;
				}

				.send-confirmation-button {
					justify-content: flex-start;
					@include non-mobile {
						margin-left: auto;
						margin-right: 0;
					}
				}
			}
		}

		// make email part shrinkable
		.email-with-status {
			display: flex;
			align-items: center;
			flex-shrink: 1;
			width: 100%;
			padding-right: 60px;
			box-sizing: border-box;

			.text-ellipsed {
				white-space: nowrap;
			}

			.email-confirmation {
				flex: 1;
				white-space: nowrap;
			}
		}


		.email-confirmation {
			margin-left: 7px;
			margin-right: 7px;
			&.confirmed {
				@extend .icon-email-confirmed;
				color: var(--setup-success-icon-color);
			}

			&.not-confirmed {
				@extend .icon-email-not-confirmed;
				color: var(--warning-icon-color);
			}
		}

		.email-confirmation-button.sj-button {
			@include link-styled;

			@include mobile {
				margin-top: 10px;
			}
		}

		.pending-email {
			font-weight: bold;
		}
	}

	.external-account-link {
		display: inline-block;
		margin-top: 10px;
	}
}
