.setup {
	align-self: center;

	width: 400px;
	padding-top: 30px;

	@include mobile_small {
		padding-top: 0;
		width: 100%;
	}

	.steps-content form {

		.sj-form-group {
			&.password,
			&.password-strength {
				margin-bottom: var(--gap-s);
			}
		}

		.sj-message {
			display: block;
			width: 100%;
			max-width: 100%;
			margin-bottom: 20px;
		}

		.hint {
			margin: 20px 0;
		}

		.sj-checkbox {
			margin-top: 10px;

			.check {
				margin-right: 10px;
				display: inline-block;
			}
		}
	}

	.buttons > button {
		width: 100%;
	}
}

.setup-active {
	&.app {
		.drawer {
			display:none;
		}

		> .content {
			margin-left: $content-left-offset;
		}
	}
}


$setupStepSize: 50px;
$setupStepSpacing: 50px;

.setup-steps {
	position: relative;
	margin-bottom: 20px;

	font-size: $setupStepSize * 0.55;
	line-height: $setupStepSize;
	color: var(--body-background-color);

	width: 100%;

	display: flex;
	justify-content: center;
}

.setup.success .setup-steps {
	visibility: hidden;
}


.setup-step {
	background: var(--setup-step-active-color);
	border-radius: 50%;
	height: $setupStepSize;
	width: $setupStepSize;
	position: relative;
	text-align: center;

	transition: background-color 0.3s ease-out;

	counter-increment: setup-step-index;

	&:not(:last-child) {
		margin-right: $setupStepSpacing;

		&:after {
			content: "";
			display: inline-block;
			position: absolute;
			width: $setupStepSpacing;
			height: 2px;
			top: $setupStepSize * 0.5;
			left: $setupStepSize;
			background-color: var(--setup-step-active-color);
		}
	}

	&:before {
		@extend .fa-solid;
		content: '#{$fa-var-check}';
	}

	&.active,
	&.active ~ .setup-step {
		&:before {
			content:counter(setup-step-index);
			font-family: $base-font-family;
		}

		&:not(:last-child):after {
			background-color: var(--setup-step-in-active-color);
		}
	}

	&.active ~ .setup-step {
		background: var(--setup-step-in-active-color);
	}
}

.setup-success {
	text-align: center;

	.icon-setup-done {
		display: block;
		margin-top: 0.5em;
		margin-bottom: 0.5em;

		color: var(--setup-success-icon-color);
	}

	.pending-email {
		font-weight: bold;
	}

	.buttons {
		margin-top: 36px;
	}
}
