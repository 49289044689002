.form-header {
	h1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.form-body {
	.buttons {
		margin-top: 38px;
		display: flex;

		.delete-button {
			@include link-styled;
		}

		.sj-button-disabled {
			display: none;
		}

		@include mobile {
			flex-direction: column;
			.sj-button {
				display: block;
				width: 360px;
				max-width: 100%;
				margin-bottom: 30px;
			}
		}
	}
}
