.app-list {
	flex-direction: column;

	// To center the .empty-text element in case of an empty list
    justify-content: center;

	.empty-text {
		color: var(--empty-item-color);
	}
}

.app-entry {
	display: flex;
	align-items: center;
	margin: 5px 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.app-label {
		flex: 1;
	}

	.app-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 10px;
		width: 40px;
		height: 40px;
	}
}
