
.password {
	.wrap-line {
		// Center password placeholder vertically
		align-items: center;
	}
}

password-edit {
	.sj-form-group {
		&.recommendation {
			.field {
				height: auto;
			}
		}

		&.new-password {
			margin-bottom: 0;
		}

		&.password-strength {
			margin-bottom: var(--gap-xs);

			.field {
				padding-top: var(--gap-s);
				align-items: flex-start;
			}
		}

		@include mobile {
			&.new-password,
			&.password-strength {
				margin-bottom: 0;
			}
		}
	}
}

