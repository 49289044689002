.two-fa {
	align-items: center;

	flex: 1 1 auto;
	display: flex;

	.description {
		display: flex;

		margin-right: 10px;

		.icon {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	&.wrap-line > .edit-button {
		margin-left: auto;
	}

	.activate-hint {
		display: flex;
		flex: 1;

		margin-right: 10px;

		justify-content: center;
		align-items: center;
	}

	@include mobile {
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		.description,
		.activate-hint {
			justify-content: flex-start;

			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}

.two-fa-info-step {
	display: flex;
	flex-direction: column;

	.sj-message {
		width: 100%;
		max-width: 100%;
		text-align: left;
		margin-top: 30px;
	}

	.wrap {
		flex: 1 0 auto;
	}

	.img {
		display: inline-block;
		margin: 0 30px 10px 0;
		float: left;

		svg {
			width: 100px;
			height: 100px;
		}
	}
}

.two-fa-codeScan {
	display: flex;
	flex-direction: column;

	.code-step-wrap {
		flex: 1 0 auto;
	}

	.qr-code-summary {
		display: flex;
		margin: 15px 0;
		@include mobile_small {
			flex-direction: column;
		}
	}

	p {
		margin: 0;
		line-height: 19px;
	}

	.code-qr {
		margin-right: 30px;
		@include mobile {
			margin-right: 15px;
		}
		width: 200px;
		height: 200px;
		padding: 2px;
		background-color: var(--two-fa-code-background);
	}

	.code-info {
		@include mobile_small {
			margin-top: 20px;
		}

		> .label {
			display: block;
			color: var(--form-label-disabled-color);
			line-height: 16px;
		}
		.field {
			display: block;
			margin-bottom: 5px;
			&:last-child {
				margin-bottom: 0px;
			}
		}

		.copy-secret-trigger {
			@include link-styled;
			margin-bottom: 5px;
			display: block;
		}
	}

	.token-error {
		display: none;
		margin-top: 15px;
	}
	&.error .token-error {
		display: block;
	}

	.token-input {
		margin: 20px 0 10px;
		display: flex;
		flex-direction: row;
		.token-editor {
			max-width: 185px;
			margin-right: 10px;
		}
		.sj-button {
			margin-right: 0;
		}

		@include mobile_small {
			flex-direction: column;
			height: auto;

			.token-editor {
				max-width: 100%;
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
	}
}

.two-fa-success {
	height: 100%;
	text-align: center;
	margin: 0 auto;
	max-width: 360px;

	.icon-two-fa-enable-success {
		color: var(--icon-checked-color);
		margin: 45px auto 40px;
	}

	p {
		margin: 0;
		&.info {
			color: var(--two-fa-hint-color);
		}

		&.print-hint {
			margin-top: 20px;
		}
	}
}

.two-fa-overlay {
	.sj-overlay-content {
		width: 600px;
	}

	.two-fa-info-step,  .two-fa-codeScan, .two-fa-success {
		min-height: 500px;

		@include mobile {
			padding-bottom: 50px;
		}
	}
}

@include print {
	.two-fa-overlay {
		.two-fa-success > :not(.print-content) {
			display: none;
		}

		.sj-overlay-content {
			width: 100vw;
		}

		.print-content {
			position: absolute;

			top: 0;
			left: 0;

			.qr-code-summary {
				display: flex;

				margin-left: 20px;
				margin-top: 20px;

				.code-qr {
					width: 200px;
					height: 200px;
					margin-right: 20px;
					padding-top: 5px;
				}

				.code-info {
					text-align: left;

					.copy-secret-trigger {
						display: none;
					}
				}

				.label {
					display: block;
					line-height: 16px;
					color: #ccc;
				}

				.field {
					display: block;
					margin-bottom: 5px;
				}
			}
		}
	}
}
