@mixin darkmode-variables {
	--body-background-color: #333333;
	--body-text-color: #f9f9f9;
	--body-text-color-light: #ddd;
	--button-primary-active-background-color: #999999;
	--button-primary-active-border-color: #aaaaaa;
	--button-primary-background-color: #444444;
	--button-primary-border-color: #777777;
	--button-primary-color: #eeeeee;
	--button-primary-disabled-background-color: #444444;
	--button-primary-disabled-border-color: #444444;
	--button-primary-hover-background-color: #666666;
	--button-primary-hover-border-color: #444444;
	--button-secondary-active-background-color: #666;
	--button-secondary-active-border-color: #666;
	--button-secondary-active-color: #fff;
	--button-secondary-background-color: #333333;
	--button-secondary-border-color: #888;
	--button-secondary-color: #eeeeee;
	--button-secondary-hover-background-color: #666666;
	--button-secondary-hover-border-color: #444444;
	--dropdown-arrow-color: #666;
	--dropdown-background-color: #999999;
	--dropdown-disabled-background-color: #666666;
	--dropdown-focused-arrow-color: #444;
	--dropdown-focused-border-color: var(--body-text-color);
	--dropdown-hover-arrow-color: #555;
	--dropdown-hover-border-color: var(--body-text-color);
	--drop-up-down-flyout-normal-color: #eeeeee;
	--editor-selection-background-color: #40B5F5;
	--editor-validation-error-icon-color: #ff4433;
	--flyout-link-selected-background-color: #444444;
	--form-message-error-color: #ff4433;
	--h1-color: var(--body-text-color);
	--link-active-color: #eeeeee;
	--link-color: #999999;
	--link-hover-color: #dddddd;
	--link-icon-active-color: #dddddd;
	--link-icon-hover-color: #eeeeee;
	--menu-background-color: #383639;
	--message-background-color: var(--secondary);
	--message-negative-border-color: var(--error2);
	--message-positive-border-color: var(--success2);
	--oauth2-logo-color: #fff;
	--text-editor-background-color: #444444;
	--text-editor-disabled-background-color: #666;
	--text-editor-error-border-color: #ff4433;
}
