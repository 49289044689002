$navigation-icon-width: 50px;
$navigation-item-height-desktop: 50px;
$navigation-item-height-mobile: 40px;

.sj-navigation {
	margin-top: calc($header-bottom-space - var(--gap-xs)); // The navigation section element has intrinsic spacing

	@include non-desktop {
		padding-left: 5px;
		padding-right: 30px;
	}

	li {
		list-style-type: none;
	}

	// split main items by separator
	> .sj-navigation-item:not(:last-of-type) {
		border-bottom: 1px var(--navigation-separator-color) dotted;
		padding-bottom: var(--gap-s);
		margin-bottom: var(--gap-s);
	}
}

.sj-navigation-item {
	a {
		box-sizing: border-box;
		color: var(--navigation-menu-item-color);
		position: relative;
		min-width: $navigation-icon-width;
		display: flex;
		align-items: center;

		.icon {
			height: 22px;
			color: var(--navigation-icon-color);

			// the height of the icons can vary so flexboxing is needed here
			// for vertical centering.
			display: flex;
			align-items: center;

			justify-content: unset;

			&:after {
				content: "";
				position: absolute;
				top: 12px;
				left: $navigation-icon-width;
				width: 130px;
				height: 0;
			}
		}

		.inline-icon {
			height: 20px;
			width: 20px;

			color: var(--navigation-icon-color);
		}

		.icon,
		.inline-icon {
			padding-right: 18px;

			& + .label {
				color: var(--navigation-icon-color);
				font-weight: bold;
			}
		}

		.label {
			font-weight: 600;
		}

		padding: var(--gap-xs);

		.sj-navigation-group {
			display: block;
		}
	}

	&.selected {
		& > a {
			.label {
				font-weight: bold;
				color: var(--navigation-menu-item-selected-color);
			}

			// rules for label with prepended icon
			.icon,
			.inline-icon {
				& + .label {
					color: var(--navigation-icon-color);
				}
			}
		}

		.sj-navigation-group {
			display: block;
		}
	}
}

.sj-navigation-group {
	> .sj-navigation-item {
		min-height: $navigation-item-height-mobile;
		@include desktop {
			min-height: $navigation-item-height-desktop;
		}
		a {
			border: none;
			min-height: $navigation-item-height-mobile;

			@include non-desktop {
				min-height: $navigation-item-height-mobile;
			}

			@include desktop {
				min-height: $navigation-item-height-mobile;
				top: ($navigation-item-height-desktop - $navigation-item-height-mobile) * 0.5;
			}

			@include hover {
				background-color: var(--navigation-hover-background-color);
				cursor: pointer;
				border-radius: 8px;
			}

			@include active {
				background-color: var(--navigation-active-background-color);
			}
		}

		.label {
			word-break: break-word;
		}
	}
}
