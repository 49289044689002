.teamfolder-edit {
	.coredata-form {
		width: 100%;

		.edit-button {
			float: right;
		}
	}
}

.legacy-hint {
	color: var(--form-label-disabled-color);

	.coredata-display:not(.is-legacy-teamfolder) & {
		display: none;
	}
}

.is-legacy-teamfolder {
	.sj-form-horizontal .sj-form-group {
		max-width: inherit;

		> .field {
			flex: 1;
			// Determined manually to fit /public with enough spacing
			// to prevent text wrapping *before* the mobile breakpoint
			// applies when the navigation is still visible.
			width: 90px;
		}
	}

	.teamfolder-edit {
		.sj-form-group {
			max-width: inherit;
		}
	}

	.edit-button-mobile, .edit-button {
		display: none;
	}
}
