.rating {
	.rating-label {
		margin-right: 8px;
		vertical-align: middle;
		font-weight: 600;
	}

	.star {
		cursor: pointer;

		margin-right: 5px;
		vertical-align: middle;

		color: var(--feedback-rating-color);

		&.active {
			color: var(--feedback-rating-active-color);
		}
	}
}
