.search-files {
	.common-loader {
		flex-direction: column;

		button {
			margin: var(--gap-s);
		}
	}

	.toolbar-row .fm-folder-flyout {
		.icon-level-up,
		.sep,
		.folder-icon,
		.arrow {
			display: none;
		}
	}

	.toolbar-row .file-selected-count {
		@include tablet {
			display: inline-block;
		}

		@include tablet_large {
			display: none;
		}
	}
	.toolbar-row .file-type-count {
		@include mobile {
			display: inline-block;
		}

		@include mobile_small {
			display: none;
		}
	}

	x-filemanager list-layout {
		@include portable() {
			.file-item-category {
				display: none;
			}

			.file-item-path {
				width: 150px;
			}
		}

		.file-item-path {
			display: block;

			@include hover {
				text-decoration: underline;
			}

			@include tablet_large() {
				display: none;
			}
		}
	}
}

$search-control-border-radius: 8px;
$search-control-dropdown-item-height: 48px;
$search-control-dropdown-item-gap: 2px;
$search-control-gap: var(--gap-s);
$search-control-checkbox-border-radius: 2px;
$search-control-highlight-border-radius: 4px;
$search-menu-border-radius: 8px;

.search-form {
	form {
		display: flex;

	}

	button[type=submit] {
		flex: 0 0 auto;
		min-width: auto;
		width: 44px;
		border-radius: $search-control-border-radius;
		margin-right: 0;
	}

	.sj-editor {
		flex: 0 0 auto;
		width: 100px;

		@media only screen and (min-width: 1200px) {
			width: 175px;
		}
		@media only screen and (min-width: 1300px) {
			width: 275px;
		}
		@media only screen and (min-width: 1400px) {
			width: 375px;
		}

		display: inline-block;

		position: relative;
		margin-right: $search-control-gap;

		border-radius: $search-control-border-radius;
		border: 1px solid var(--search-menu-border-color);

		input {
			padding-right: 35px;
			text-overflow: ellipsis;
		}

		button {
			cursor: pointer;
			border: none;
			background-color: transparent;
			position: absolute;
			right: 0;
			top: -1px;
			width: 40px;
			height: $button-height;
			color: var(--icon-search-clear-input-color);
		}
	}
	.filter-wrapper {
		display: flex;
	}

	fieldset {
		flex: 0 0 auto;
		display: inline-block;
		margin-right: $search-control-gap;

		.icon-flyout-caret-dropped-down,
		.icon-flyout-caret-dropped-up {
			font-size: 14px;
			margin-left: $search-control-gap;
			vertical-align: middle;
		}

		.icon-flyout-caret-dropped-down {
			color: var(--search-menu-caret-down-color);
		}

		button-with-clear {
			display: flex;

			height: $button-height;

			button {
				cursor: pointer;
				padding: 9px 12px;
				color: var(--search-menu-button-color);
				font-weight: 600;

				height: $button-height;

				margin: 0;
			}

			.main-button {
				background-color: var(--search-menu-button-background-color);
				border-radius: $search-control-border-radius;
				border: 1px solid var(--search-menu-button-border-color);

				&.active {
					border-color: var(--search-menu-button-active-border-color);
				}

				@include hover {
					border-color: var(--search-menu-button-active-border-color);
				}
			}

			.clear + .main-button {
				border-radius: 0 $search-control-border-radius $search-control-border-radius 0;
				border: 1px solid var(--search-menu-button-border-color);
				border-left-color: var(--search-menu-button-border-left-color);
				background-color: var(--search-menu-button-selected-background-color);
				color: var(--search-menu-selected-button-color);

				@include hover {
					background-color: var(--search-menu-button-background-hover-color);
					border-left-color: none;
				}

				> .icon-flyout-caret-dropped-down {
					color: var(--search-menu-caret-down-selected-color);
				}
			}

			.clear {
				border-radius: $search-control-border-radius 0 0 $search-control-border-radius;
				border: 1px solid var(--search-menu-clear-button-border-color);
				border-right: transparent;
				background-color: var(--search-menu-clear-button-background-color);
				color: var(--search-menu-clear-button-color);

				@include hover {
					background-color: var(--search-menu-clear-button-hover-background-color);
				}
			}
		}
	}

	.sj-menu {
		display: grid;
		grid-gap: $search-control-dropdown-item-gap;
		border: 1px solid var(--search-menu-border-color);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: $search-menu-border-radius;
		margin-top: $search-control-gap;
		padding: $search-control-gap;
		overflow: auto;

		/* landscape layout*/
		@media only screen and (max-height: 590px) {
			max-height: 7 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		@media only screen and (max-height: 542px) {
			max-height: 6 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		@media only screen and (max-height: 494px) {
			max-height: 5 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		@media only screen and (max-height: 446px) {
			max-height: 4 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		@media only screen and (max-height: 398px) {
			max-height: 3 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		@media only screen and (max-height: 350px) {
			max-height: 2 * ($search-control-dropdown-item-height + $search-control-dropdown-item-gap) - $search-control-dropdown-item-gap;
		}

		label {
			cursor: pointer;

			height: $search-control-dropdown-item-height;
			padding: 0 16px;

			font-weight: 600;
			line-height: $search-control-dropdown-item-height;
			color: var(--search-menu-label-color);
			border-radius: $search-control-highlight-border-radius;

			&.selected {
				background-color: var(--search-menu-label-selected-background-color);
			}

			@include hover {
				background-color: var(--search-menu-label-hover-background-color);
				color: var(--search-menu-label-hover-color);

				.icon-checkbox-checked {
					color: var(--search-menu-checkbox-hover-color);
				}
			}

			@include active {
				background-color: var(--search-menu-label-active-background-color);
				color: var(--search-menu-label-active-color);

				> .icon-checkbox-checked {
					color: var(--search-menu-checkbox-active-color);
				}
			}

			input {
				display: none;
			}

			.icon-checkbox-checked {
				padding: 4px;
				margin-right: 9px;

				color: var(--search-menu-checkbox-color);
			}

			&.selected {
				.icon-checkbox-checked {
					color: var(--search-menu-checkbox-checked-color);
					background-color: var(--search-menu-checkbox-background-color);
					border-radius: $search-control-checkbox-border-radius;
				}
			}
		}
	}

	@include portable {
		display: flex;
		position: relative;

		form {
			flex: 1 1 100%;
		}

		.sj-editor {
			flex: 1 1 auto;
			width: auto;
		}

		.filter-wrapper {
			position: absolute;
			top: calc($button-height + var(--gap-s));
		}
	}

	@include mobile {
		.sj-menu {
			position: fixed;
			left: $content-left-offset-mobile-small;
			right: $content-right-offset-mobile-small;
		}

		.filter-wrapper {
			overflow: auto;
			/* hides horizontal scrollbar for Firefox */
			scrollbar-width: none;

			width: 100vw;

			/* scroll till the edge of the screen */
			margin-left: - $content-left-offset-mobile-small;
			padding-left: $content-left-offset-mobile-small;

			padding-right: $content-right-offset-mobile-small;
			box-sizing: border-box;

			/* hides horizontal scrollbar for webkit browsers */
			&::-webkit-scrollbar {
				display: none;
			}
		}

		fieldset:last-child {
			margin-right: 0;
		}
	}
}
