.teamfolder-list-loader {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 200px;

	color: var(--loading-icon-color);
}

.teamfolder-grid {

	.menubar-row {
		display: flex;
		align-items: center;

		.menubar {
			margin-left: auto;
		}
	}

	@include error-or-empty-view {
	}
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	position: relative;

	list-layout {
		flex: 1
	}

	.label-icon {
		@include svg-icon-default;
		height: 40px;
		width: 40px;

		display: inline-block;
		margin: 10px 10px 0;
		@include mobile_small {
			display: none;
		}
	}

	.item-name {
		flex: 1;

		min-width: 200px;
	}

	.item-size {
		width: 120px;
		@include mobile() {
			display: none;
		}
	}

	.item-button-container {
		@extend %item-list-button-container;
	}
}
