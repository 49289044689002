.diag {
	height: 100%;

	display: flex;
	flex-direction: column;

	.common-loader {
		margin-top: $header-height;
	}

	.content-wrap {
		margin: $header-height 50px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-self: center;
		font-size: 18px;
		font-weight: 600;
		text-align: center;

		@include desktop {
			font-size: 20px;
		}

		&.successful {
			max-width: 600px;
		}

		&.failed {
			max-width: 700px;
		}
	}

	.icon-test-successful {
		color: var(--icon-checked-color);
		margin: 0 auto 30px;
	}

	.icon-test-failed {
		color: var(--icon-unchecked-color);
		margin: 0 auto 30px;
	}

}
