#sfm-header {
	flex: none;
	// fix top position on scrolled and non-overflowing content
	top: 0;
	background-color: var(--sfm-header-color);
	height: $header-height;
	width: 100%;
	padding: 0 $header-footer-hor-padding;
	position: fixed;
	color: var(--sfm-header-text-color);
	box-sizing: border-box;

	display: flex;
	align-items: center;

	z-index: 5;

	.burger-menu-button {
		flex: 0 0 auto;
		margin-right: 10px;
		cursor: pointer;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;

		@include mobile {
			// compensate for button touch outline
			margin-left: -12px;
		}

		@include desktop {
			display: none;
		}
	}

	.logo {
		cursor: pointer;
		height: 40px;

		> a {
			height: 40px;
			display: flex;
			align-items: center;
		}
	}

	.spacer {
		display: block;
		flex: 1;
	}


	.logout {
		display: inline-block;
		cursor: pointer;
		color: var(--sfm-header-link-color);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		flex: 0 1 auto;
		max-width: 40%;

		margin-left: 30px;

		@include mobile {
			margin-left: 20px;
		}

		@include mobile_small {
			margin-left: 15px;
		}


		@include hover {
			color: var(--sfm-header-link-hover-color);
		}

		@include active {
			color: var(--sfm-header-link-active-color);
		}

		.logout-text,
		.logout-user-name,
		.icon-logout {
			vertical-align: middle;
		}

		.logout-text {
			font-weight: 600;
			margin-left: 5px;
		}

		@include mobile_small() {
			.logout-text,
			.logout-user-name {
				display: none;
			}
		}
	}

	.sales-link,
	.sales-link-mobile {
		min-width: auto;
		border-color: var(--sales-link-border-color);
		color: var(--sales-link-color);
		background-color: transparent;

		@include hover {
			color: var(--sales-link-hover-color);
			background-color: var(--sales-link-hover-background-color);
			border-color: var(--sales-link-hover-border-color);
		}

		@include active {
			color: var(--sales-link-active-color);
			background-color: var(--sales-link-active-background-color);
			border-color: var(--sales-link-active-border-color);
		}
	}

	.sales-link {
		@include mobile_small {
			display: none;
		}
	}

	.sales-link-mobile {
		display: none;
		@include mobile_small {
			display: block;
		}
	}
}

@include print {
	#sfm-header {
		display: none;
	}
}
