.protocol-path {
	.sj-overlay-content {
		width: 600px;
	}

	.list {
		border: var(--protocol-path-overlay-list-border);
		overflow-y: auto;
		max-height: 370px;
		@include mobile_small {
			max-height: none;
		}
	}

	.entry:nth-child(2n+1) {
		background-color: var(--protocol-path-overlay-list-background-color);
	}

	.entry {
		display: flex;
		min-height: 40px;
		padding: 10px;
		box-sizing: border-box;
		border-bottom: var(--protocol-path-overlay-list-border);

		@include mobile {
			display: block;
		}

		&:last-child {
			border: none;
		}

		.os {
			font-weight: bold;
			width: 175px;
		}

		.path {
			flex: 1;
			word-break: break-all;
		}
	}
}
