.footer-on-screen .uploadstatus-wrapper {
	position: relative;
	/* shift mini upload status into the gap between filemanager & footer */
	bottom: 10px;
}

.uploadstatus-wrapper {
	position: fixed;
	height: 0;
	bottom: 20px + $footer-height;
	width: 100%;

	z-index: 2;

	display: flex;
	justify-content: center;

	.maximize, .icon-upload-close {
		@extend %icon-link;
	}
}
