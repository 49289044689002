.user-edit,
.invitation-display,
.user-invite,
.teamfolder-add,
.teamfolder-edit {
	.icon-level-up {
		color: var(--level-up-color);
		cursor: pointer;
		margin-right: 4px;
		font-size: 40px;
	}
}

.sharemanager, .users, .devicebackup {
	flex: 1 0 auto;
}

.filemanager, .sharemanager, .users, .devicebackup, .mailuploads, .shareuploads {
	background-color: var(--sfm-filemanager-background);
}

.filemanager, .sharemanager-body, .mailuploads-body, .shareuploads-body {
	margin-top: $menubar-height;

	@include mobile {
		margin-top: $menubar-height + 15px;
	}
}

.filemanager {
	@include portable {
		margin-top: $menubar-height + $button-height;
	}

	@include mobile {
		margin-top: $menubar-height + $button-height + 15px;
	}
}

.filemanager, .sharemanager-body, .mailuploads-body, .shareuploads-body {
	list-content, tile-content, fluid-content {
		// enables contextmenu and file drag&drop in empty view
		transform: translateY(0);
	}

	list-content {
		display: block;
	}
}

.teamfolder-grid,
.sharemanager-header,
.mailuploads-header,
.shareuploads-header,
.users-header,
.snapshots,
.devicebackup-header,
.settings {
	h1 {
		margin-top: 0;
		line-height: var(--gap-xxl);
	}
}

.sharemanager-header, .mailuploads-header, .shareuploads-header {
	position: fixed;
	right: $content-right-offset;
	top: $header-height;
	left: $content-left-offset;
	width: auto;
	height: 100px;//byDesign
	z-index: 3;

	padding-top: $header-bottom-space;
	padding-bottom: $header-bottom-space;
	display: flex;
	flex-direction: column;

	background-color: var(--body-background-color);

	.toolbar {
		font-size: 18px;
	}

	@include desktop {
		left: $content-left-offset-desktop;
	}

	@include mobile {
		padding-bottom: 0;
	}

	@include mobile_small {
		left: $content-left-offset-mobile-small;
		right: $content-right-offset-mobile-small;
	}
}

.users-header {
	.meta-info {
		font-size: 18px;
	}
}

x-filemanager {
	margin: $fm-dropzone-border-size;

	list-header {
		margin-top: -$fm-dropzone-border-size;

		right: calc($content-right-offset - $fm-dropzone-size);
		left: calc($content-left-offset - $fm-dropzone-size);

		 @include desktop {
			right: calc($content-right-offset - $fm-dropzone-size);
			left: calc($content-left-offset-desktop - $fm-dropzone-size);
		}
	}

	list-content {
		margin: 0 calc($fm-dropzone-size - $fm-dropzone-border-size) calc($fm-dropzone-size - $fm-dropzone-border-size);
		@include mobile_small {
			margin: 0 -#{$fm-dropzone-border-size};
		}
	}
}

.sh-grid,
.mu-grid,
.snapshots-grid,
.shareuploads-grid,
.devicebackups-grid
{

	list-header {
		right: $content-right-offset;
		left: $content-left-offset;

		@include desktop {
			left: $content-left-offset-desktop;
		}
	}

	list-content {
		display: block;
		border-top: none;
		margin: 0 $fm-dropzone-size;
		@include mobile_small {
			margin: 0;
		}
	}
}

.devicebackup,
.settings,
.teamfolder-grid,
.snapshots,
.users {
	margin: 0 $fm-dropzone-size;
	@include mobile_small {
		margin: 0;
	}
}

@import "./contentPageList";
