.snapshots {
	@include error-or-empty-view;

	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	h2 {
		margin-top: 80px;
		margin-bottom: 10px;
	}

	.zones-dropdown-container {
		background-color: var(--form-background-color);
		border-radius: $formBorderRadius;
		padding: 0 30px;

		.text {
			display: inline-block;
			margin: 30px 20px 0 0;
		}

		.sj-simple-dropdown {
			margin: 30px 0 30px 0;
			display: inline-block;
		}
	}

	.error {
		margin: auto;
		text-align: center;

		h3 {
			color: var(--empty-item-color);
		}
	}
}

.snapshots-schedule-hint {
	.sj-message {
		width: 100%;
		max-width: 100%;

		h4 {
			margin: 0;
		}
	}
}

.snapshot-schedule-edit {
	.sj-form {
		background-color: var(--form-background-color);
		padding: 30px 30px 40px;
		border-radius: $formBorderRadius;
	}

	&.no-ttl .ttl .sj-label {
		color: var(--form-label-disabled-color);
	}

	@include mobile_small {
		.sj-form {
			padding-bottom: 10px;
		}

		.sj-button {
			display: block;
			width: 100%;
			margin-bottom: 30px;
		}
	}
}

.snapshot-schedule-display-container {
	width: 100%;

	border-top: var(--sfm-main-section-separator-line-style);
	border-bottom: var(--sfm-main-section-separator-line-style);

	margin-top: var(--gap-l);

	.sj-form-horizontal {
		min-height: 40px;
		padding: 10px 0;
	}

	.edit-button {
		float: right;

		@include mobile {
			display: none;
		}
	}

	.edit-button-mobile {
		margin-bottom: 10px;
		width: auto;

		@include iconButtonPosition();

		.label {
			display: inline-block;
		}

		@include non-mobile {
			display: none;
		}
	}
}
