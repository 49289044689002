$listHeaderHeight: 55px;

.sh-grid,
.mu-grid,
.users-grid,
.snapshots-grid,
.shareuploads-grid,
.teamfolder-grid,
.devicebackups-grid {
	@include mobile(){
		margin-top: 0;
		padding-bottom: 0;
	}

	@include mobile_small(){
		padding-bottom: 10px;
	}

	&.isEmpty list-header, &.isEmpty .footer {
		display: none;
	}

	list-header {
		list-item {
			height: $listHeaderHeight;
			padding-top: 8px;
			color: var(--list-header-color);

			.file-item-label, .item-label {
				line-height: $listHeaderHeight;
			}

			.item-label.sortable {
				cursor: pointer;

				.icon-list-ascending,
				.icon-list-descending {
					margin-left: 10px;
					display: none;
					vertical-align: text-top;
				}

				&:hover {
					color: var(--list-header-hover-color);

					.icon-list-ascending {
						display: inline-block;
					}
				}

				&.sorted.asc {
					.icon-list-ascending {
						display: inline-block;
					}
					.icon-list-descending {
						display: none;
					}
				}

				&.sorted.desc {
					.icon-list-ascending {
						display: none;
					}
					.icon-list-descending {
						display: inline-block;
					}
				}
			}
		}
	}

	list-item {
		display: block;
		box-sizing: border-box;
		color: var(--fm-item-list-color);
		position: relative;
		overflow: hidden;
		float: none;
		width: auto;
		height: 60px;
		background-color: var(--list-bg-color);
		border-bottom: var(--list-item-border);
		margin: 0;
		padding: 0 0 0 50px;
		white-space: nowrap;
	}

	.itemcontent {
		position: absolute;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: row;
	}

	.file-item-label, .item-label {
		vertical-align: top;
		line-height: 60px;
		padding: 0 10px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

// rules for grids with fixed header
.sh-grid,
.mu-grid,
.shareuploads-grid {
	height: 100%; /* to allow for vertical centered empty/error view */

	list-header {
		display: block;
		position: fixed;
		z-index: 2;

		@include mobile() {
			display: none;
		}
	}

	list-content {
		padding-top: $listHeaderHeight;

		@include mobile {
			padding-top: 0;
		}
	}

	&.isEmpty {
		list-content {
			padding-top: 20px;
			height: 100%;
		}
	}
}

%item-list-button-container {
	display: block;
	width: 50px;
	flex-shrink: 0;
	align-self: center;

	.sj-icon-only-button {
		vertical-align: middle;
	}
}

%item-list-status-background {
	&.expired {
		background-color: var(--list-item-expired-background-color);
	}

	&.invalid {
		background-color: var(--list-item-invalid-background-color);
	}
}
