.users-grid {
	@include error-or-empty-view;

	.invitation {
		.item-icon,
		.item-alias,
		.item-role {
			opacity: 0.3;
		}

		.item-used {
			// maintain the size but hide the content so that the flexed layout
			// does not break.
			visibility: hidden;
		}
	}

	.item-label {
		display: block;
	}

	.icon-user, .icon-user-secret {
		font-size: 32px;
		width: 40px;
		color: var(--list-item-icon-color);
	}

	.item-alias, .item-account {
		flex: 1;
	}

	.item-role {
		width: 150px;

		@include mobile() {
			display: none;
		}
	}

	.item-used {
		width: 150px;

		@include mobile() {
			width: 70px;
		}
	}

	.item-button-container {
		@extend %item-list-button-container;
	}
}
