teamfolder-permission-edit {
	.label {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex: 1;
	}

	.field {
		flex: 0 0 300px;
	}

	&.sj-form {
		.sj-form-group {
			max-width: none;
			margin: 0;
			padding-bottom: 10px;

			border-bottom: var(--sfm-main-section-separator-line-style);
		}
		.sj-form-group:not(:first-child) {
			padding-top: 10px;
		}
		.sj-simple-dropdown {
			margin: 0;
		}
	}
}
