.settings {
	height: 100%; /* to allow for vertical centered empty/error view */

	@include error-or-empty-view {
	}

	.section {
		margin-top: var(--gap-xxl);
	}
}
