$header-height: 58px;
$header-bottom-space: var(--gap-l);

$menubar-height: 100px;

$drawer-width: 210px;
$drawer-to-content-space: 40px;
$drawer-bottom-offset: 30px;

$footer-height: 60px;
$footer-top-space: 30px;

$form-group-bottom-margin: 28px;

$content-left-offset: var(--gap-l);
$content-left-offset-desktop: $drawer-width + $drawer-to-content-space;
$content-left-offset-mobile-small: 16px;
$content-right-offset: var(--gap-l);
$content-right-offset-mobile-small: 16px;

// the maximum width for pages containing mixed content (sections, text, lists).
// not used in fullpage grids.
$content-max-width: 860px;

$header-footer-hor-padding: var(--gap-l);

:root {
	--gap-xs: 8px;
	--gap-s: 12px;
	--gap-m: 16px;
	--gap-l: 24px;
	--gap-xl: 32px;
	--gap-xxl: 48px;
}
