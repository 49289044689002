.footer-on-screen {
	.drawer {
		bottom: $drawer-bottom-offset + $footer-height;
	}
}

.scroll-to-top {
	position: fixed;
	bottom: 20px;

	> .label {
		display: none;
	}

	.footer-on-screen & {
		position: relative;
		display: block;
		right: 0;
		margin: auto;

		@include desktop {
			float: right;
			margin-right: 30px;
		}
	}

	width: 40px;
	min-width: 40px;

	z-index: 2;

	background-image: var(--scroll-top-button-icon);
	background-size: contain;
	background-repeat: no-repeat;

	background-color: transparent;
	border: none;

	@include hover {
		background-color: transparent;
	}

	right: 50%;
	margin-right: -20px;	// keep it centered
	@include desktop {
		right: 30px;
		margin-right: 0;
	}

	transform: translateY(100px);
	$defaultTransition: transform .5s ease, opacity .5s ease;
	transition: $defaultTransition;

	&.sj-button-disabled {
		opacity: 0;
		background-color: transparent;
		height: 0;
		transition: $defaultTransition, height 0.1s ease 0.5s;
	}

	&:not(.sj-button-disabled) {
		opacity: 1;
		transform: translateY(0);
	}
}

.app {
	flex: 1 0 auto;

	display: flex;
	flex-direction: column;

	> .content {
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;

		box-sizing: border-box;

		padding-top: calc($header-height + $header-bottom-space);
		padding-bottom: $footer-top-space;
		margin-left: calc($content-left-offset - $fm-dropzone-size);
		margin-right: calc($content-right-offset - $fm-dropzone-size);

		> div:not(.setup) {
			.common-loader {
				left: $content-left-offset;
				right: $content-right-offset;
				top: calc($header-height + $header-bottom-space);

				@include desktop {
					left: $content-left-offset-desktop;
				}

				@include mobile {
					top: calc($header-height + $header-bottom-space + 20px);
				}
			}

		}

		@include desktop {
			margin-left: calc($content-left-offset-desktop - $fm-dropzone-size);
		}

		@include mobile {
			min-height: 90vh;
		}

		@include mobile_small {
			margin-left: $content-left-offset-mobile-small;
			margin-right: $content-right-offset-mobile-small;
		}
	}
}
