#content {

	min-width: 280px;
	// Make content fit the whole viewport height if not scrollable
	height: 100%;
	// Use flexbox to allow correct height calculation when decendant elements
	// (like the footer) have a margin-bottom. Neccessary in Firefox ~65
	display: flex;
	flex-direction: column;
	// needed so that eu-cookie doesn't enforce a scrollbar
	box-sizing: border-box;
}

#content-switcher {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}

.content-wrap {
	flex: 1 0 auto;
}

// wrapper for mixed content pages with a max width
.content-container {
	max-width: $content-max-width;
}

.overlayed {
	overflow: hidden;
}

.print-content {
	display: none;
}

@include print {
	#content {
		display: none;
	}

	.print-content {
		display: block;
	}
}
