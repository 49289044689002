.create-file-overlay {
	.sj-overlay-content {
		width: 380px;
		@include mobile_small {
			width: 100vw;
		}
	}

	.create-file-overlay-body {
		.file-name {
			display: flex;
			align-items: center;

			.sj-text-editor {
				flex-grow: 1;

				margin-left: 10px;
				margin-right: 5px;
			}
		}

		.file-icon {
			width: 40px;
			height: 40px;

			@include svg-icon-default;
		}
	}
}
