.protocol-edit {
	.sj-list-checker-items {
		display: flex;
		flex-wrap: wrap;

		$protocolCheckboxMargin: 20px;
		.sj-checkbox {
			white-space: nowrap;
			max-width: 50%;

			margin-bottom: $protocolCheckboxMargin;

			flex: 1 0 50%;

			@include mobile {
				max-width: 100%;
				flex: 1 0 100%;
			}

			> label {
				overflow: hidden;
				flex: 0 1 auto;
			}

			.checkbox-text {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		[data-key=key-webdav] {
			order: 1;

			@include mobile {
				order: 1;
			}
		}

		[data-key=key-git] {
			order: 2;

			@include mobile {
				order: 4;
			}
		}

		[data-key=key-cifs] {
			order: 3;

			@include mobile {
				order: 2;
			}
		}

		[data-key=key-scp] {
			order: 4;

			@include mobile {
				order: 5;
			}
		}

		[data-key=key-rsync] {
			order: 5;
			margin-bottom: 0;

			@include mobile {
				order: 3;
				margin-bottom: $protocolCheckboxMargin;
			}
		}

		[data-key=key-ftp] {
			order: 6;
			margin-bottom: 0;

			@include mobile {
				order: 6;
			}
		}
	}

	.protocols-hint {
		margin-top: 24px;
	}
}
